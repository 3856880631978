import React, { useState } from "react";
import { connect } from "react-redux";
import * as auth from "modules/Auth/_redux/authRedux";
import { GoogleLogin } from "react-google-login";
import JWTService from "@core/auth/JWTService";
import * as authCrud from "modules/Auth/_redux/authCrud";
import { withRouter } from "react-router-dom";
import "./social-styles.css";
import FacebookLogin from "react-facebook-login";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function SocialBar(props) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const responseGoogle = (googleResponse) => {
    setLoading(true);
    JWTService.googleProviderSign(googleResponse)
      .then((userInformation) => {
        if (userInformation.role === "temporary") {
          props.history.push(`/otp`);
        } else {
          const pageToRedirectAfterLogin = localStorage.getItem(
            "pageToRedirectAfterLogin"
          );

          props.history.push(
            pageToRedirectAfterLogin ? pageToRedirectAfterLogin : `/`
          );
          localStorage.removeItem("pageToRedirectAfterLogin");
        }
        props.googleLogin(userInformation);
        setLoading(false);
      })
      .then((userInformation) => {
        authCrud.info().then((res) => props.setBalance(res.data.balance));
      });
  };

  const responseGoogleFailure = (err) => {
    console.log("Google Auth Check : Guest ", err);
  };

  const responseFacebook = (facebookResponse) => {
    if (facebookResponse?.status == "unknown") return;

    setLoading(true);
    JWTService.facebookProviderSign(facebookResponse)
      .then((userInformation) => {
        if (userInformation.role === "temporary") {
          props.history.push(`/otp`);
        } else {
          const pageToRedirectAfterLogin = localStorage.getItem(
            "pageToRedirectAfterLogin"
          );

          props.history.push(
            pageToRedirectAfterLogin ? pageToRedirectAfterLogin : `/`
          );
          localStorage.removeItem("pageToRedirectAfterLogin");
        }
        props.googleLogin(userInformation);
        setLoading(false);
      })
      .then((userInformation) => {
        authCrud.info().then((res) => props.setBalance(res.data.balance));
      });
  };

  // const responseApple = (appleResponse) => {
  //   JWTService.appleProviderSign(appleResponse)
  //     .then((userInformation) => {
  //       if (userInformation.role === "temporary") {
  //         props.history.push(`/otp`);
  //       } else {
  //         props.history.push(`/`);
  //       }
  //       props.googleLogin(userInformation);
  //     })
  //     .then((userInformation) => {
  //       authCrud.info().then((res) => props.setBalance(res.data.balance));
  //     });
  // };

  const componentClicked = (response) => {
    console.log(response);
  };

  return (
    <div className="dz-social clearfix">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/*<h5 className="form-title m-t5 pull-left">veya</h5>*/}
      <ul className="dez-social-icon dez-border  dez-social-icon-lg text-white d-flex flex-row justify-content-center">
        {/* <li>
          <AppleLogin
            clientId={"com.alicilar.web"}
            redirectURI={"https://alicilar.com/apple/login"}
            responseType={"code"}
            responseMode={"query"}
            usePopup={false}
            designProp={{
              height: 50,
              width: 130,
              color: "white",
              border: true,
              //type: "sign-in",
              border_radius: 15,
              //scale: 1,
              locale: "tr_TR",
            }}
          />
        </li>*/}
        <li>
          <GoogleLogin
            //icon={false}
            className="login-google-btn"
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Google"
            icon="q-icon icon-google i-google"
            onSuccess={(e) => responseGoogle(e)}
            onFailure={(err) => responseGoogleFailure(err)}
            cookiePolicy={"single_host_origin"}
            //redirectUri={"https://alicilar.com"}
          />
        </li>
        <li>
          <FacebookLogin
            appId="553493530517586"
            cssClass="my-facebook-button-class"
            textButton="Facebook"
            autoLoad={false}
            icon="fa fa-facebook"
            fields="first_name,last_name,email,picture"
            onClick={(e) => componentClicked(e)}
            callback={(e) => responseFacebook(e)}
          />
        </li>
      </ul>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default connect(null, auth.actions)(withRouter(SocialBar));
