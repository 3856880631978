import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as adminEndpoint from "../../_redux/adminEndpoint";

const formData = new FormData();

export function DropZone(props) {
  const [frontFile, setFrontFile] = useState(0);
  const [loading, setLoading] = useState(false);

  // test
  const onDrop = useCallback((acceptedFiles) => {
    let ins = acceptedFiles.length;
    setFrontFile(ins);
    for (let x = 0; x < ins; x++) {
      formData.append(`multiupload[${x}]`, acceptedFiles[x]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function uploadImage() {
    setLoading(true);

    adminEndpoint
      .uploadAdvertImageFromAdmin(props.productId, formData)
      .then((res) => res.data.advert)
      .then((advert) => {
        props.setAdvert(advert);
        setLoading(false);
        formData.delete("multiupload[0]");
        formData.delete("multiupload[1]");
        formData.delete("multiupload[2]");
      });
  }

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p
            style={{
              height: 150,
              width: 300,
              backgroundColor: "#eeffeb",
              borderRadius: 10,
              padding: 10,
              color: "black",
            }}
          >
            Şimdi bırak
          </p>
        ) : (
          <p
            style={{
              height: 150,
              width: 300,
              backgroundColor: "#eeffeb",
              borderRadius: 10,
              padding: 10,
              color: "black",
            }}
          >
            Dosya seçiniz ({frontFile} )
          </p>
        )}
      </div>

      {!loading ? (
        <button
          className="btn btn-solid"
          disabled={!frontFile}
          onClick={uploadImage}
        >
          yükle
        </button>
      ) : (
        "Yükleniyor."
      )}
    </div>
  );
}
