const parseDigits = (string) => (string.match(/\d+/g) || []).join("");

// Card Number
export const numberFormat = (string) => {
  const digits = parseDigits(string);
  return digits.substr(0, 16);
};
// Card Number
export const turkishLowerCase = (string) => {
  if (!string) return "";
  return (
    string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase("tr-TR")
  );
};

// CVC
export const cvcFormat = (string) => {
  const digits = parseDigits(string);
  return digits.substr(0, 3);
};

// CARD DATE
export const dateFormat = (string) => {
  const digits = parseDigits(string);
  const chars = digits.split("");
  return chars
    .reduce(
      (r, v, index) => (index === 2 || index === 4 ? `${r}/${v}` : `${r}${v}`),
      ""
    )
    .substr(0, 5);
};

// UTC DATE
export const getUTCDate = (string) => {
  return new Date(string).toLocaleString("tr-TR", { timeZone: "UTC" });
};

// generate slug from human text
export function convertToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "ığüşçàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "iguscaaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}
