import { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import AppContext from "AppContext";

import * as initialEndpoint from "modules/Initial/_redux/initialEndpoint";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as authRedux from "modules/Auth/_redux/authRedux";
import * as initialRedux from "modules/Initial/_redux/initialRedux";
import * as categoryRedux from "modules/Category/_redux/categoryRedux";
import * as messageRedux from "modules/Auth/_redux/messageRedux";
import { getChatrooms } from "modules/Auth/_redux/messageCrud";

function DynamicRoutes(props) {
  const appContext = useContext(AppContext);
  const { routes } = appContext;

  const isLogged = useSelector((state) => state.auth.isLogged);
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    initialEndpoint
      .getInitial()
      .then((res) => res.data)
      .then((result) => {
        //props.setAdverts(result.adverts.results);
        props.setRandomAdverts(result.random);
        props.setCategories(result.categories);
        props.setProducts(result.products);
        props.setPages(result.pages);
        props.setSelectedCities(result.cities);
        props.setUserCity(result.userCity);
      });
  }, []);

  useEffect(() => {
    if (isLogged) {
      getChatrooms()
        .then((res) => res.data)
        .then((data) => {
          props.setChatrooms({
            chatrooms: data,
            currentUserId: currentUser.id,
          });
        });
    }
  }, [isLogged]);

  return renderRoutes(routes);
}
export default connect(null, {
  ...advertRedux.actions,
  ...authRedux.actions,
  ...initialRedux.actions,
  ...categoryRedux.actions,
  ...messageRedux.actions,
})(withRouter(DynamicRoutes));
