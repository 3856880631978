import FastAdvertCreationPage from "../FastAdvertCreationPage";
import Homepage from "../Homepage";

const MainPublicRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/hizli_ilan_olustur",
      component: FastAdvertCreationPage,
    },
    {
      path: "/",
      component: Homepage,
    },
  ],
};

export default MainPublicRoute;
