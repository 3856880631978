import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetPageDetail: "[Page] Set Page Detail",
};

const initialInitialState = {
  pageDetail: {
    title: "",
  },
};

export const reducer = persistReducer(
  { storage, key: "v713-alicilar-page", whitelist: [] },
  (state = initialInitialState, action) => {
    switch (action.type) {
      case actionTypes.SetPageDetail: {
        const { pageDetail } = action.payload;
        return { ...state, pageDetail };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setPageDetail: (pageDetail) => ({
    type: actionTypes.SetPageDetail,
    payload: { pageDetail },
  }),
};
