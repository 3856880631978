import AdvertDetail from "../views/AdvertDetail";

const AdvertPublicRoute = {
  routes: [
    {
      path: "/ilan/:advertSlug",
      component: AdvertDetail,
    },
  ],
};

export default AdvertPublicRoute;
