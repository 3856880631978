import Contact from "../views/Contact";

const ContactRoute = {
  routes: [
    {
      path: "/iletisim",
      component: Contact,
    },
  ],
};

export default ContactRoute;
