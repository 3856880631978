import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import * as adminRedux from "modules/Admin/_redux/adminRedux";
import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { convertToSlug } from "helpers";

let filterTypes = [
  { _id: 2, name: "Çoğul Aramalı Seçim", type: "multiSelect" },
  { _id: 3, name: "Tekik Aramalı Seçim", type: "singleSelect" },
  { _id: 4, name: "Çoğul Seçmeli", type: "multiCheck" },
];

function FilterCreateDialog({
  open,
  toggleCreateCategoryDialog,
  addFilterCategory,
}) {
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    e.persist();

    if (e.target.name === "title") {
      setForm((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
        slug: convertToSlug(e.target.value),
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleArray = (e) => {
    setForm((prevState) => ({
      ...prevState,
      values: e,
    }));
  };

  const handleSave = (e) => {
    addFilterCategory(form);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
      },
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={toggleCreateCategoryDialog}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleCreateCategoryDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          <TextField
            onChange={(e) => handleChange(e)}
            label="Filtre Başlık"
            variant="outlined"
            type="text"
            name="title"
          />

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="parentCategoryId">Filtre Tipi</InputLabel>
            <Select
              labelId="parentCategoryId"
              id="parentCategoryId"
              label="Kategori"
              name="filterType"
              //value={category}
              //onChange={(e) => handleArray(e)}
              onChange={(e) => handleChange(e)}
            >
              {filterTypes.map((e, index) => (
                <MenuItem key={index} value={e.type}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} variant="outlined">
            <br />
            <h3>Seçenekler</h3>
            <div>Herbir seçenek için enter tuşuna basınız. </div>
            <InputTag receiveData={(e) => handleArray(e)} />
          </FormControl>

          <div>
            <Button variant="contained" onClick={(e) => handleSave(e)}>
              Ekle
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

function InputTag(props) {
  const [tags, setTags] = useState([]);
  const tagText = useRef();

  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  useEffect(() => {
    props.receiveData(tags);
  }, [tags]);

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setTags([...tags, val]);
      tagText.current.value = "";
    } else if (e.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };

  return (
    <div className="input-tag">
      <ul className="input-tag__tags">
        {tags.map((tag, i) => (
          <li key={tag}>
            {tag}
            <button
              type="button"
              onClick={() => {
                removeTag(i);
              }}
            >
              *
            </button>
          </li>
        ))}
        <li className="input-tag__tags__input">
          <input type="text" onKeyDown={inputKeyDown} ref={tagText} />
        </li>
      </ul>
    </div>
  );
}

export default connect(null, { ...adminRedux.actions })(FilterCreateDialog);
