import JWTService from "./JWTService";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as authRedux from "modules/Auth/_redux/authRedux";
import loader from "../../images/loader.gif";

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([this.jwtCheck()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      JWTService.checkToken()
        .then((user) => {
          // this.props.setUserData(user);
          this.props.setUser(user.data.user);
          this.props.setBalance(user.data.balance);
          resolve();
          // this.props.showMessage({ message: "Logged in with JWT" });
        })
        .catch((error) => {
          // Yes, interesting. We are need to soft check the user. maybe something changed server side.
          resolve();
        });

      JWTService.init();
      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100vh", background: "#059265" }}
      >
        <img src={loader} alt="alicilar" style={{ border: "none" }} />
      </div>
    ) : (
      <>{this.props.children}</>
    );
  }
}

export default connect(null, { ...authRedux.actions })(Auth);
