import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as initialEndpoint from "modules/Initial/_redux/initialEndpoint";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";

function ProvinceDistrictSelect(props) {
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const { newAdvert } = useSelector((state) => ({
    newAdvert: state.advert.newAdvert,
  }));

  useEffect(() => {
    initialEndpoint
      .getTurkeyProvince()
      .then((res) => res.data)
      .then((result) => {
        setProvinces(result.provinces);
      });
  }, []);

  const selectProvince = (e, selectedProvince) => {
    if (selectedProvince !== null) {
      props.editNewAdvertProvince(selectedProvince);
      props.editNewAdvertDistrict(null);

      getDistricts(selectedProvince);
    } else {
      setDistricts([]);
    }
  };

  const selectDistrict = (e, selectedDistrict) => {
    props.editNewAdvertDistrict(selectedDistrict);
  };

  const getDistricts = (selectedProvince) => {
    setDistricts(selectedProvince.districts);
  };
  /*const getDistricts = (code) => {
    initialEndpoint
      .getTurkeyDistrictByCode(code)
      .then((res) => res.data)
      .then((result) => {
        setDistricts(result.districts);
      });
  };*/

  return (
    <Box flexWrap="wrap">
      <Box flex="row">
        <Autocomplete
          onChange={(e, selectedProvince) =>
            selectProvince(e, selectedProvince)
          }
          limitTags={1}
          //defaultValue={newAdvert.location.province}
          id="multiple-limit-province"
          options={provinces}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="İl" />
          )}
        />
      </Box>
      <Box flex="row">
        <Autocomplete
          onChange={(e, selectedProvince) =>
            selectDistrict(e, selectedProvince)
          }
          disabled={!districts.length > 0}
          limitTags={1}
          value={newAdvert.location.district}
          //defaultValue={newAdvert.location.district}
          id="multiple-limit-district"
          options={districts}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="İlçe" />
          )}
        />
      </Box>
    </Box>
  );
}
export default connect(null, { ...advertRedux.actions })(
  ProvinceDistrictSelect
);
