/**
 * Authorization Roles
 */
const AuthRoles = {
  admin: ["admin"],
  staff: ["admin", "staff"],
  user: ["admin", "staff", "user"],
  temporary: ["temporary"],
  onlyGuest: [],
};

export default AuthRoles;
