import React, { Component } from "react";

function HeaderSearchBar(props) {
  return (
    <div
      className="header-nav navbar-collapse collapse myNavbar justify-content-center"
      id="navbarNavDropdown"
    >
      <div className="dez-bnr-inr-entry align-m  p-4">
        <div className=" job-search-form shadow ">
          <form onSubmit={props.handleSubmit}>
            <div className="input-group  navbar-search-bar">
              <input
                type="text"
                name="kelime"
                className="form-control "
                value={props.keyword}
                onChange={(e) => props.handleChange(e)}
                placeholder="İlan, kelime ara..."
              />
              <div className="input-group-prepend">
                <button
                  className="site-button "
                  type="submit"
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HeaderSearchBar;
