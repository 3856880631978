import React from "react";
import { connect } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as authRedux from "modules/Auth/_redux/authRedux";
import * as initialRedux from "modules/Initial/_redux/initialRedux";
import AdminLayout from "markup/Layout/AdminLayout";
import MaterialTable from "material-table";
import { getUTCDate } from "helpers";
import * as adminEdnpoint from "modules/Admin/_redux/adminEndpoint";

function AdvertIndex(props) {
  return (
    <AdminLayout>
      <div className="page-content bg-white">
        <MaterialTable
          title="İlanlar"
          options={{
            search: false,
            pageSize: 20,
            //toolbar: false,
            rowStyle: (rowData) => ({
              backgroundColor: !rowData.approved ? "#f6cccc" : "#f1fae8",
            }),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} / {count}",
            },
            header: {
              actions: "İşlem",
            },
            body: {
              emptyDataSourceMessage: "",
              filterRow: {
                filterTooltip: "Filtre",
              },
            },
          }}
          actions={[
            {
              icon: "apps",
              tooltip: "Detay",
              onClick: (event, rowData) => {
                // props.history.push(`/admin/adverts/${rowData.id}`);
                window.open(`/admin/adverts/${rowData.id}`);
              },
            },
          ]}
          columns={[
            { title: "id", field: "ID", hidden: true },
            { title: "Başlık", field: "title" },
            { title: "İlan No", field: "_id" },
            {
              title: "Resim",
              field: "images",
              render: (rowData) => (
                <img src={rowData.images} style={{ width: 50 }} />
              ),
            },

            { title: "Kategori", field: "category.name" },

            {
              title: "Üyelik",
              field: "createdAt",
              render: (rowData) => <span>{getUTCDate(rowData.createdAt)}</span>,
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              adminEdnpoint
                .getAdverts(query.page, query.pageSize, "desc")
                .then((res) => res.data)
                .then((result) => {
                  resolve({
                    data: result.results,
                    page: result.page - 1,
                    totalCount: result.totalResults,
                  });
                });
            })
          }
          editable={{
            isEditable: (rowData) => rowData.id,
            isDeletable: (rowData) => rowData.id,
          }}
        />
      </div>
    </AdminLayout>
  );
}

export default connect(advertRedux.reducer, {
  ...advertRedux.actions,
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdvertIndex);
