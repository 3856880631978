import AuthRoles from "@core/auth/AuthRoles";
import SmsOtp from "../SmsOtp";

const AuthRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: AuthRoles.temporary,

  routes: [
    {
      path: "/otp",
      component: SmsOtp,
    },
  ],
};
export default AuthRoute;
