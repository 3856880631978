import React, { useEffect } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import LayoutV1 from "./LayoutV1";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";

let bnr = require("images/banner/bnr1.jpg");

const array = [
  { id: 1, title: "Panel", link: "dashboard", icon: "home" },
  { id: 2, title: "İlan", link: "adverts", icon: "account_box" },
  { id: 3, title: "Üye", link: "users", icon: "face" },
  { id: 4, title: "Satış", link: "orders", icon: "favorite_border" },
  { id: 5, title: "Açılan İlanlar", link: "boughts", icon: "lock_open" },
  { id: 6, title: "Ürün", link: "products", icon: "inventory_2" },
  { id: 7, title: "Sayfa", link: "pages", icon: "account_box" },
  { id: 8, title: "Kategori", link: "categories", icon: "category" },
  { id: 9, title: "Kampanyalar", link: "campaigns", icon: "star" },
  /*{ id: 5, title: "Ayar", link: "settings", icon: "settings" },*/
  { id: 10, title: "Ara", link: "search", icon: "search" },
];

function AdminLayout(props) {
  // const { user } = useSelector(
  //   (state) => ({
  //     user: state.auth.user,
  //   }),
  //   shallowEqual
  // );

  useEffect(() => {}, []);

  return (
    <LayoutV1>
      <div
        className="overlay-black-dark profile-edit "
        style={{ backgroundImage: "url(" + bnr + ")" }}
      >
        <div className="container">
          <div className="d-flex flex-row flex-wrap">
            {array.map((item, index) => (
              <div key={index} className="p-1 m-1">
                <Link to={`/admin/${item.link}`}>
                  <div
                    className="border bg-gray p-3 radius-md d-flex flex-column align-items-center "
                    style={{ width: 90, fontSize: 13 }}
                  >
                    <Icon>{item.icon}</Icon>

                    <p style={{ margin: 0, textWrap: "nowrap" }}>
                      {item.title}
                    </p>
                  </div>
                  {/*<Button
                    className="button"
                    variant="contained"
                    color="secondary"
                    endIcon={<Icon>{item.icon}</Icon>}
                  >
                    {item.title}
                  </Button>*/}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-5">{props.children}</div>
        </div>
      </div>
    </LayoutV1>
  );
}

export default AdminLayout;
