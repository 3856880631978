import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function LayoutV1(props) {
  return (
    <div className="page-wraper">
      <Header />
      {props.children}
      <Footer />
    </div>
  );
}

export default LayoutV1;
