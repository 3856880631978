import { TextField } from "@material-ui/core";
import axios from "axios";
import { isValidTurkishPhoneNumber } from "helpers/PhoneFormats";
import LayoutV1 from "markup/Layout/LayoutV1";
import PhoneField from "modules/Auth/parts/PhoneField";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function PaidAdvertContactPage() {
  const User = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    fullname: "",
    message: "",
    email: "",
    phoneNumber: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const submitForm = (e) => {
    e.preventDefault();

    setSuccess(false);
    const { fullname, message, email, phoneNumber } = formData;

    if (!fullname || !message || !email || !phoneNumber)
      return toast.error("Lütfen tüm alanları doldurun");

    if (!isValidTurkishPhoneNumber(phoneNumber))
      return toast.error("Lütfen geçerli bir telefon numarası giriniz");

    setIsLoading(true);
    axios
      .post("/v1/paidAdvertisement/contact", formData)
      .then(() => {
        setIsLoading(false);
        setSuccess(true);
        toast.success("Mesajınız Gönderildi");

        setFormData({ fullname: "", message: "", email: "", phoneNumber: "" });
      })
      .catch((err) => {
        setSuccess(false);
        setIsLoading(false);
        if (err?.response?.data?.message)
          toast.error(err?.response?.data?.message);
      });
  };

  return (
    <LayoutV1>
      <Helmet>
        <title>Alıcılar</title>
        <meta
          name="description"
          content="Almak istediğin ne varsa ilan ver satıcısı seni bulsun !"
        />
      </Helmet>

      <div className="container py-3">
        <h3 className="text-center">Reklam İletişim Formu</h3>

        <form onSubmit={submitForm}>
          <div className="row">
            <div className="col-md-4 mb-3">
              <TextField
                type="text"
                name="fullname"
                variant="outlined"
                label="İsim"
                onChange={(e) => handleFormData("fullname", e.target.value)}
                value={formData.fullname}
                style={{ width: "100%", background: "white" }}
              />
            </div>

            <div className="col-md-4 mb-3">
              <TextField
                type="email"
                name="email"
                variant="outlined"
                label="E-Posta"
                onChange={(e) => handleFormData("email", e.target.value)}
                value={formData.email}
                style={{ width: "100%", background: "white" }}
              />
            </div>

            <div className="col-md-4 mb-4">
              <PhoneField
                value={formData.phoneNumber}
                onChange={(e) => handleFormData("phoneNumber", e.target.value)}
                style={{
                  minHeight: 50,
                  height: "100%",
                  borderRadius: 5,
                  borderColor: "#ccc",
                }}
              />
            </div>

            <div className="mb-3 col-12">
              <TextField
                type="text"
                name="message"
                variant="outlined"
                label="Mesajınız"
                multiline
                rows={10}
                onChange={(e) => handleFormData("message", e.target.value)}
                value={formData.message}
                style={{ width: "100%", background: "white" }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 col-md-3">
              {success ? (
                <p className="text-success font-weight-bold">
                  Mesajınız Gönderildi...
                </p>
              ) : (
                <button
                  className="w-100 btn text-white"
                  style={{ background: "#1FB284" }}
                >
                  {isLoading ? "Gönderiliyor..." : "Gönder"}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </LayoutV1>
  );
}

export default PaidAdvertContactPage;
