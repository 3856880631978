import React, { useState } from "react";
import MaterialTable from "material-table";
import * as adminEndpoint from "modules/Admin/_redux/adminEndpoint";
import AdminLayout from "markup/Layout/AdminLayout";
import { Dialog, TextField } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";

const AdminPages = (props) => {
  const [dialog, setDialog] = useState(false);
  const [createCampaign, setCreateCampaign] = useState({});

  function handleChange(ev) {
    setCreateCampaign({
      ...createCampaign,
      [ev.target.name]: ev.target.value,
    });
  }
  function handleSave() {
    adminEndpoint.createCampaign(createCampaign).then((res) => {
      toast.success("Oluşturuldu.");
      window.location.reload();
    });
    setDialog(false);
  }

  return (
    <AdminLayout className="col-md-12">
      <MaterialTable
        title="Kampanyalar"
        options={{ search: false }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} / {count}",
          },
          header: {
            actions: "İşlem",
          },
          body: {
            emptyDataSourceMessage: "",
            filterRow: {
              filterTooltip: "Filtre",
            },
          },
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Yeni Kampanya Oluştur",
            isFreeAction: true,
            onClick: (event) => setDialog(true),
          },
        ]}
        columns={[
          { title: "id", field: "ID", hidden: true },
          { title: "Kampanya Adı", field: "name" },
          { title: "Açıklama", field: "body" },
          { title: "Kampanya Kodu", field: "code" },
          { title: "Bakiye", field: "acoin" },
          { title: "Oluşturma", field: "createdAt" },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            adminEndpoint
              .getCampaigns(query.page, query.pageSize, "desc")
              .then((res) => res.data)
              .then((result) => {
                resolve({
                  data: result.results,
                  page: result.page - 1,
                  totalCount: result.totalResults,
                });
              });
          })
        }
        editable={{
          isEditable: (rowData) => rowData.id,
          isDeletable: (rowData) => rowData.id,
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              adminEndpoint.deleteCampaign(oldData.id).then((result) => {
                resolve();
              });
            }),
        }}
      />
      <CreateCampaignDialogBox
        dialog={dialog}
        toggleDialog={() => setDialog(false)}
        handleChange={(ev) => handleChange(ev)}
        handleSave={(ev) => handleSave(ev)}
      />
    </AdminLayout>
  );
};

function CreateCampaignDialogBox({
  dialog,
  toggleDialog,
  handleChange,
  handleSave,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
      },
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

  const classes = useStyles();
  return (
    <Dialog open={dialog}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => toggleDialog()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          Yeni Kampanya Oluştur
        </Toolbar>
      </AppBar>

      <div className={classes.root}>
        <TextField
          onChange={(e) => handleChange(e)}
          label="Başlık"
          variant="outlined"
          type="text"
          name="name"
        />
        <TextField
          onChange={(e) => handleChange(e)}
          label="Açıklama"
          variant="outlined"
          type="text"
          name="body"
        />
        <TextField
          onChange={(e) => handleChange(e)}
          label="Kampanya Kodu"
          variant="outlined"
          type="text"
          name="code"
        />
        <TextField
          onChange={(e) => handleChange(e)}
          label="Bakiye Miktarı"
          variant="outlined"
          type="number"
          name="acoin"
        />
        <div>
          <Button variant="contained" onClick={(e) => handleSave(e)}>
            Ekle
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AdminPages;
