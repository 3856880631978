import React from "react";
import InputMask from "react-input-mask";

function PhoneField(props) {
  const { value, onChange } = props;
  return (
    <InputMask
      allowShowMask={true}
      mask="+\90 (999) 999 99 99"
      value={value}
      onChange={onChange}
      style={props?.style}
    >
      {(inputProps) => (
        <input
          {...inputProps}
          name="phone"
          className="form-control"
          value={value}
          onChange={onChange}
          placeholder="Telefon No"
          type="tel"
        />
      )}
    </InputMask>
  );
}

export default PhoneField;
