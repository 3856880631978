import axios from "axios";

export const GET_ADVERTS = "v1/connections";
export const GET_ADVERTS_OF_CATEGORY = "v1/connections/city";
export const GET_ADVERT_DETAIL = "v1/connections/";
export const CREATE_ADVERT = "v1/connections";
export const CREATE_ADVERT_WITHOUT_LOGIN = "v1/connections/withoutLogin";
export const SEARCH_ADVERT = "v1/connections/search";

// Take and buy advert
export const TAKET_ADVERT = "v1/cashier/takeAdvert/";

export function getAdverts() {
  return axios.get(GET_ADVERTS);
}

/**
 * getAdvertsWithPaginate
 * @param {number} page
 * @param {number} limit
 * @param {string} order
 */
export function getAdvertsWithPaginate(page, limit, order) {
  let url = `${GET_ADVERTS}?page=${page}&limit=${limit}&sortBy=createdAt:${order}`;
  return axios.get(url);
}

export function getAdvertsOfCityWithPaginate(page, limit, order, city) {
  let url = `${GET_ADVERTS_OF_CATEGORY}?page=${page}&limit=${limit}&sortBy=createdAt:${order}&city=${city}`;
  return axios.get(url);
}

export function getAdvertDetail(advertId) {
  return axios.get(GET_ADVERT_DETAIL + advertId);
}

export function getSimilarAdverts(advertId, body) {
  return axios.post(GET_ADVERT_DETAIL + advertId + "/similar", body);
}

export function takeAdvert(advertId) {
  return axios.get(TAKET_ADVERT + advertId);
}

export function createAdvert(advertFormData) {
  return axios.post(CREATE_ADVERT, advertFormData);
}

export function createAdvertWithoutLogin(formData) {
  return axios.post(CREATE_ADVERT_WITHOUT_LOGIN, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function searchAdvert(keyword) {
  return axios.post(SEARCH_ADVERT, { keyword });
}
