import React from "react";
import FLMultiSelect from "./filters/FLMultiSelect";
import FLSingleSelect from "./filters/FLSingleSelect";

function CreateAdvertFilters(props) {
  return (
    <div className="d-flex flex-column ">
      {props.filters.map(
        (filter) =>
          ({
            multiSelect: <FLMultiSelect filter={filter} key={filter.id} />,
            singleSelect: <FLSingleSelect filter={filter} key={filter.id} />,
            multiCheck: <FLMultiSelect filter={filter} key={filter.id} />,
          }[filter.filterType])
      )}
    </div>
  );
}

export default CreateAdvertFilters;
