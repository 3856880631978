import React from "react";

import "react-pro-sidebar/dist/css/styles.css";
import LayoutV1 from "./LayoutV1";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
let bnr = require("images/banner/bnr1.jpg");

function UserProfileLayout(props) {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  return (
    <LayoutV1>
      <div
        className="overlay-black-dark profile-edit p-t50 p-b20"
        style={{ backgroundImage: "url(" + bnr + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="candidate-info">
              <div className="candidate-detail">
                <div className="text-white browse-job text-left">
                  <h4 className="m-b0">
                    {user.name} {user.surname}
                  </h4>
                  <p className="m-b15">Merhaba {user.name}, Hoşgeldin.</p>
                  <ul className="clearfix">
                    {/*<li>
                      <i className="ti-location-pin"></i> Ataşehir, İstanbul
                    </li>*/}

                    {user.phone ? (
                      <li
                        className="d-flex align-items-center"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <i className="ti-mobile"></i> {user.phone}
                      </li>
                    ) : null}

                    {/*<li>
                      <i className="ti-briefcase"></i> Alıcı Modu
                  </li>*/}

                    <li className="d-flex align-items-center">
                      <i className="ti-email"></i> {user.email}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {props.children}
    </LayoutV1>
  );
}

export default UserProfileLayout;
