import StaticPage from "../StaticPage";

const PagePublicRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/sayfa/:pageSlug",
      component: StaticPage,
    },
  ],
};

export default PagePublicRoute;
