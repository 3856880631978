import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as initialEndpoint from "modules/Initial/_redux/initialEndpoint";
import { generateCityList } from "helpers";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as initialRedux from "modules/Initial/_redux/initialRedux";

function CitySection(props) {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { cities, userCity } = useSelector(
    (state) => ({
      cities: state.initial.selectedCities,
      userCity: state.initial.userCity,
    }),
    shallowEqual
  );

  function showAnotherCities() {
    setLoading(true);
    initialEndpoint
      .getTurkeyProvince()
      .then((res) => res.data)
      .then((result) => {
        props.setSelectedCities(result.provinces);
        setLoading(false);
        setLoaded(true);
      });
  }

  return (
    <div
      className="section-full content-inner bg-gray city-inner"
      style={{ paddingTop: "40px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 section-head text-center">
            <h2 className="m-b5">Konum İlanları</h2>
            <h6 className="fw4 m-b0">Tüm şehirler için ilanları listeleyin</h6>
          </div>
        </div>
        <div className="row">
          {/*<div className="col-lg-4 col-sm-6 col-md-6 m-b30">
            <Link to={generateCityList(userCity?.region_code)}>
              <div className="city-bx align-items-end d-flex">
                <div className="city-info">
                  <h5>Size yakın ilanlar</h5>{" "}
                  <h6 style={{ color: "white" }}> </h6>
                  <span style={{ fontSize: 10, lineHeight: 0.9 }}>
                    {userCity?.region_name}
                    {" / "}
                    {userCity?.country_name}{" "}
                    {userCity?.location?.country_flag_emoji}
                  </span>
                </div>
              </div>
            </Link>
          </div>*/}

          {cities.map((item, index) => (
            <div className="col-lg-2 col-sm-6 col-md-6 m-b30" key={item.plate}>
              <Link to={generateCityList(item.plate)}>
                <div
                  className="city-bx align-items-end d-flex"
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                >
                  <div className="city-info">
                    <h5>{item.name}</h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}

          <div className="col-lg-12 text-center m-t30">
            {!loaded && (
              <button
                disabled={loading && true}
                className="site-button radius-xl"
                onClick={() => showAnotherCities()}
              >
                {loading ? "Yükleniyor..." : "Tüm Şehirler"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { ...initialRedux.actions })(CitySection);
