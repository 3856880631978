import React, { useEffect, useRef } from "react";

const DeleteMessageModal = ({ closeModal, deleteMessage }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div className="deleteMessageModalScreenContainer">
      <div className="deleteMessageModal" ref={modalRef}>
        <h4>Mesaj Silinsin Mi?</h4>

        <div className="d-flex flex-column items-center">
          <button className="text-danger" onClick={deleteMessage}>
            Sil
          </button>
          <button onClick={closeModal}>İptal</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMessageModal;
