// import React from "react";
import axios from "axios";
import _ from "lodash";

// export function makeAuth(alicilarAuthData) {
//   setupToken(alicilarAuthData);
//   setupAxiosAuthorization(axios);
// }

export function removeAuth(alicilarAuthData) {
  removeToken();
}
//
// export function setupToken(data) {
//   let tokenAccess = data.tokens.access.token;
//   let tokenRefresh = data.tokens.refresh.token;
//
//   localStorage.setItem("tokenAccess", tokenAccess);
//   localStorage.setItem("tokenRefresh", tokenRefresh);
// }

export function removeToken(token) {
  localStorage.clear();
  delete axios.defaults.headers.Authorization;
  window.location.reload();
}

// export function setupAxiosAuthorization(axios, store) {
//   console.log("setupAxiosAuthorization");
//   let existToken = localStorage.getItem("tokenAccess");
//   if (existToken) {
//     axios.interceptors.request.use(
//       (config) => {
//         config.headers.Authorization = `Bearer ${existToken}`;
//         return config;
//       },
//       (err) => Promise.reject(err)
//     );
//   }
//
//   // TODO : need to be move
//   axios.interceptors.response.use(
//     (response) => response,
//     ({ message, response: { data, status } }) => {
//       console.log({ data, status });
//       toast.error(data.message);
//       return handleError({ message, data, status });
//     }
//   );
// }

export const handleError = ({ message, data, status }) => {
  return Promise.reject({ message, data, status });
};

export function hasPermission(authArr, userRole) {
  /**
   * If auth array is not defined
   * Pass and allow
   */
  if (authArr === null || authArr === undefined) {
    // console.info("auth is null || undefined:", authArr);
    return true;
  }
  if (authArr.length === 0) {
    /**
     * if auth array is empty means,
     * allow only user role is guest (null or empty[])
     */
    // console.info("auth is empty[]:", authArr);
    return !userRole || userRole.length === 0;
  }
  /**
   * Check if user has grants
   */
  // console.info("auth arr:", authArr);
  /*
          Check if user role is array,
          */
  if (userRole && Array.isArray(userRole)) {
    return authArr.some((r) => userRole.indexOf(r) >= 0);
  }

  /*
          Check if user role is string,
          */
  return authArr.includes(userRole);
}

function setRoutes(config, defaultAuth) {
  let routes = [...config.routes];

  routes = routes.map((route) => {
    let auth =
      config.auth || config.auth === null ? config.auth : defaultAuth || null;
    auth = route.auth || route.auth === null ? route.auth : auth;
    const settings = _.merge({}, config.settings, route.settings);

    return {
      ...route,
      settings,
      auth,
    };
  });

  return [...routes];
}

export function generateRoutesFromConfigs(configs, defaultAuth) {
  let allRoutes = [];
  configs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config, defaultAuth)];
  });
  return allRoutes;
}
