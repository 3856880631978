import React, { useEffect, useRef, useState } from "react";
import LayoutV1 from "markup/Layout/LayoutV1";
import "../../../css/messages.css";
import * as MessagesRedux from "../_redux/messageRedux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  checkChatroom,
  deleteMessageAPIEndpoint,
  getMessagesOfChatroom,
  sendMessage,
  setMessagesAsSeen,
} from "../_redux/messageCrud";
import { getAdvertDate } from "helpers";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DeleteMessageModal from "../parts/DeleteMessageModal";
import { toast } from "react-toastify";

function Messaging(props) {
  const MessagesSection = useRef();

  const CurrentChatroom = useSelector(
    (state) => state.messages.currentChatroom
  );
  const Messages = useSelector((state) => state.messages.messages);
  const User = useSelector((state) => state.auth.user);
  const LastMessageFetchDate = useSelector(
    (state) => state.messages.lastMessageFetchDate
  );
  const ShouldScrollToBottom = useSelector(
    (state) => state.messages.shouldScrollToBottom
  );
  const CheckSeenInformation = useSelector(
    (state) => state.messages.checkSeenInformation
  );

  const [deleteMessage, setDeleteMessage] = useState(null);
  const [message, setMessage] = useState("");
  const [isFetchingInitialMessages, setIsFetchingInitialMessages] = useState(
    false
  );

  const history = useHistory();

  useEffect(() => {
    if (CurrentChatroom) {
      setIsFetchingInitialMessages(true);

      getMessagesOfChatroom(CurrentChatroom._id, null)
        .then((res) => res.data)
        .then((data) => {
          setIsFetchingInitialMessages(false);

          props.updateMessagesAsArray({
            messages: data,
            currentUserId: User.id,
          });
        });
    }
  }, [CurrentChatroom]);

  useEffect(() => {
    let intervalId = setInterval(() => {
      handleMessagesFetchWithIntervals();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [LastMessageFetchDate]);

  function handleMessagesFetchWithIntervals() {
    getMessagesOfChatroom(CurrentChatroom._id, LastMessageFetchDate)
      .then((res) => res.data)
      .then((data) => {
        props.updateMessagesAsArray({ messages: data, currentUserId: User.id });
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    const queryParams = new URLSearchParams(props.location.search);
    const advertParam = queryParams.get("advert");

    checkChatroom(props.match.params.id, advertParam)
      .then((res) => res.data)
      .then((data) => {
        props.setCurrentChatroom(data);
      });

    return () => {
      props.resetMessages();
      props.resetCurrentChatroom();
    };
  }, []);

  function handleMessageSend() {
    if (message.length == 0) return;

    const messageObject = {
      sender: User,
      receiver: CurrentChatroom.participants[0],
      chatroom: CurrentChatroom._id,
      createdAt: Date.now(),
      body: message,
    };

    sendMessage(messageObject)
      .then((res) => res.data)
      .then((data) => {
        props.updateMessages(data);
      });

    setMessage("");
  }

  function handleDeleteMessage() {
    deleteMessageAPIEndpoint({ _id: deleteMessage._id })
      .then((res) => res.data)
      .then((data) => {
        props.deleteMessage(data);
        setDeleteMessage(null);
        toast.success("Mesaj silindi");
      });
  }

  useEffect(() => {
    if (MessagesSection.current && ShouldScrollToBottom) {
      MessagesSection.current.scrollTop = MessagesSection.current.scrollHeight;
    }
  }, [Messages]);

  useEffect(() => {
    if (CheckSeenInformation) {
      const unreadMessages = Messages.filter(
        (message) => message.receiver.id == User.id && !message.seen
      ).map((unreadMessage) => unreadMessage._id);

      if (unreadMessages.length > 0)
        setMessagesAsSeen(unreadMessages)
          .then((res) => res.data)
          .then(() => {
            props.setMessagesAsSeen(unreadMessages);
          });
    }
  }, [CheckSeenInformation]);

  if (!CurrentChatroom) {
    return (
      <LayoutV1>
        <div className="page-content bg-white pt-1 min-height-400">
          <h1 className="text-center m-5">Yükleniyor...</h1>
        </div>
      </LayoutV1>
    );
  }

  return (
    <LayoutV1>
      {deleteMessage ? (
        <DeleteMessageModal
          closeModal={() => setDeleteMessage(null)}
          deleteMessage={handleDeleteMessage}
        />
      ) : null}

      <div className="page-content bg-white pt-1">
        <div className="messaging-box">
          <div className="participant-info-section">
            <button onClick={() => history.goBack()}>
              <i className="fa fa-arrow-left"></i>
            </button>

            <span>{`${CurrentChatroom?.participants?.[0]?.name} ${CurrentChatroom?.participants?.[0]?.surname}`}</span>

            <Link to={`/ilan/${CurrentChatroom.advert.slug}`}>
              <p className="messagingScreenAdvertTitle">
                {CurrentChatroom.advert.title}
              </p>
            </Link>
          </div>

          <div ref={MessagesSection}>
            {isFetchingInitialMessages ? (
              <h5 className="text-center">Yükleniyor...</h5>
            ) : null}

            {Messages.map((item, index) => {
              return (
                <section
                  className={`d-flex mb-1 ${
                    item.sender.id == User.id
                      ? "currentUserMessageContainer"
                      : ""
                  }`}
                  key={index}
                >
                  {item.sender.id == User.id ? (
                    <button
                      className="messageTrashIcon"
                      onClick={() => setDeleteMessage(item)}
                    >
                      <i className="fa fa-trash " />
                    </button>
                  ) : null}

                  <div
                    className={
                      item.sender.id == User.id
                        ? "currentUserMessage"
                        : "message"
                    }
                  >
                    <p className="messageBody">{item.body}</p>
                    <p
                      className="messageDate"
                      style={item.sender.id == User.id ? { color: "#eee" } : {}}
                    >
                      {getAdvertDate(item.createdAt)}
                    </p>
                  </div>
                </section>
              );
            })}
          </div>

          <div>
            <input
              type="text"
              placeholder="Mesajınızı buraya yazın..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={handleMessageSend}>
              <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

export default connect(MessagesRedux.reducer, MessagesRedux.actions)(Messaging);
