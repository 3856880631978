import React, { useEffect } from "react";
import LayoutV1 from "markup/Layout/LayoutV1";
import "../../../css/messages.css";
import ChatroomItem from "../parts/ChatroomItem";
import { getChatrooms } from "../_redux/messageCrud";
import * as MessagesRedux from "../_redux/messageRedux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Chatrooms(props) {
  const history = useHistory();

  const Chatrooms = useSelector((state) => state.messages.chatrooms);
  const CurrentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    const interval = setInterval(() => {
      handleChatroomFetch();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function handleChatroomFetch() {
    getChatrooms()
      .then((res) => res.data)
      .then((data) => {
        props.setChatrooms({ chatrooms: data, currentUserId: CurrentUser._id });
      });
  }

  return (
    <LayoutV1>
      <div className="page-content bg-white py-4">
        <div className="container min-height-400 max-width-400">
          <h3 className="font-bold text-center">Sohbetler</h3>

          <div className="mt-4">
            {Chatrooms.length == 0 ? (
              <p className="text-center">Herhangi bir sohbetiniz yok</p>
            ) : (
              Chatrooms.map((item) => {
                return (
                  <ChatroomItem
                    key={item._id}
                    item={item}
                    history={history}
                    currentUserId={CurrentUser._id}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

export default connect(MessagesRedux.reducer, MessagesRedux.actions)(Chatrooms);
