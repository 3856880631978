import React, { useEffect, useState } from "react";
import * as adminEndpoint from "modules/Admin/_redux/adminEndpoint";
import AdminLayout from "markup/Layout/AdminLayout";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { convertToSlug } from "helpers";
import { DropZone } from "./DropZone";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/tr";
import { formatNumberWithCommas } from "helpers/FormatNumbersWithComma";
moment.locale("tr");

function AdminAdvertDetail(props) {
  const [advert, setAdvert] = useState(null);

  useEffect(() => {
    let advertId = props.match.params.advertId;
    adminEndpoint
      .getAdvert(advertId)
      .then((res) => res.data)
      .then((result) => {
        console.log({ result });
        setAdvert({
          ...result,
          displayPriceMax: formatNumberWithCommas(result.priceMax),
          displayPriceMin: formatNumberWithCommas(result.priceMin),
        });
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setAdvert((prevProps) => {
      if (name == "priceMin" || name == "priceMax") {
        let rawValue = value.replace(/,/g, "");

        if (!isNaN(rawValue) || rawValue === "") {
          if (name == "priceMin")
            return {
              ...prevProps,
              [name]: Number(rawValue),
              displayPriceMin: formatNumberWithCommas(rawValue),
            };

          if (name == "priceMax")
            return {
              ...prevProps,
              [name]: Number(rawValue),
              displayPriceMax: formatNumberWithCommas(rawValue),
            };
        }
      }
      return { ...prevProps, [name]: value };
    });
  };

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setAdvert((prevProps) => ({
      ...prevProps,
      [name]: value,
      slug: convertToSlug(value),
    }));
  };

  const setUpdatedAdvert = (event) => {
    console.log(event);
    //const { name, value } = event.target;
    /* setAdvert((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));*/
  };

  const changeAdvertSwitch = (e) => {
    const { name, checked } = e.target;
    setAdvert((prevProps) => ({
      ...prevProps,
      [name]: checked,
    }));
  };

  const handleSave = () => {
    const { displayPriceMax, displayPriceMin, ...rest } = advert;
    adminEndpoint
      .updateAdvert(props.match.params.advertId, {
        ...rest,
        sendSmsNotification,
        moderatorNote,
      })
      .then((res) => res.data)
      .then((result) => {
        toast.success("Kaydedildi.");
      });
  };

  const [sendSmsNotification, setSendSmsNotification] = useState(false);
  const [moderatorNote, setModeratorNote] = useState("");

  const handleSmsChange = () => {
    setSendSmsNotification(!sendSmsNotification);
  };

  const handleModeratorNoteChange = (e) => {
    setModeratorNote(e.target.value);
  };

  if (!advert) {
    return (
      <AdminLayout>
        <div style={{ height: 800 }}>Yükleniyor...</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="container" style={{ minHeight: 800 }}>
        <h6>
          <Link
            to="/admin/adverts"
            style={{
              borderRadius: 3,
              padding: 5,
              borderWidth: 1,
              backgroundColor: "lightgray",
            }}
          >
            ⇦ Liste
          </Link>
        </h6>
        <h3>İlan düzenle</h3>

        <div className="d-flex flex-wrap ">
          <div className="col-lg-6 p-1">
            <div className="d-flex flex-column flex-sm-row mb-4">
              <TextField
                label="Başlık"
                name="title"
                value={advert.title}
                defaultValue={advert.title}
                style={{ flex: 1 }}
                className="pr-1"
                onChange={handleTitleChange}
              />
              <TextField
                disabled
                label="URL"
                name="slug"
                value={advert.slug}
                defaultValue={advert.slug}
                className="pr-1"
                style={{ flex: 1 }}
              />
            </div>
            <div className="d-flex mb-4">
              <TextField
                label="Min Fiyat"
                name="priceMin"
                value={advert.displayPriceMin}
                defaultValue={advert.displayPriceMin}
                style={{ flex: 1 }}
                className="pr-1"
                onChange={handleChange}
              />
              <TextField
                label="Max Fiyat"
                name="priceMax"
                value={advert.displayPriceMax}
                defaultValue={advert.displayPriceMax}
                className="pr-1"
                style={{ flex: 1 }}
                onChange={handleChange}
              />
            </div>
            <div className="d-flex mb-4 ">
              <TextField
                label="Açıklama"
                name="body"
                rows={4}
                multiline
                value={advert.body}
                defaultValue={advert.body}
                style={{ flex: 1 }}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              {advert?.moderatorNote ? (
                <div className="text-dark">
                  <p className="font-bold">Moderatör Mesajı:</p>
                  <p style={{ margin: 0 }}>{advert?.moderatorNote}</p>
                </div>
              ) : null}
            </div>
          </div>

          <div className="col-lg-6  p-1" style={{ fontSize: 11 }}>
            <div className=" radius-md p-2 bg-gray mb-3 ">
              <h5>Onay Durumu</h5>

              <FormControlLabel
                control={
                  <Checkbox
                    name="approved"
                    checked={advert.approved}
                    onChange={(e) => changeAdvertSwitch(e)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="İlan Onayla / Onaylama"
              />
            </div>

            <div className=" radius-md p-2 mb-3a bg-blue mb-3 text-white">
              <h5>
                Alıcı {advert.user.deactivated ? "(Silinmiş Hesap)" : null}
              </h5>

              <div className="font-bold">
                {advert.user.name} {advert.user.surname}
              </div>
              <div>{advert.user.email}</div>
              <div>
                {advert.user.phone} - {advert.user.phone2}
              </div>
              <div>
                {moment(advert.user.createdAt).format("LL")} tarihinden beri üye
              </div>
            </div>

            <div className=" radius-md p-2 bg-green text-white">
              <h5>Temsili Görsel</h5>

              <div className="d-flex flex-row justify-content-between">
                <div>
                  <img
                    src={advert.images}
                    style={{
                      width: 100,
                      borderRadius: 5,
                      height: 100,
                      backgroundColor: "white",
                    }}
                    alt=""
                  />
                </div>

                <div>
                  <DropZone
                    productId={props.match.params.advertId}
                    setAdvert={(e) => setUpdatedAdvert(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="smsNotification"
                checked={sendSmsNotification}
                onChange={handleSmsChange}
              />
              <label className="form-check-label" htmlFor="smsNotification">
                SMS ile bilgilendir
              </label>
            </div>

            {sendSmsNotification && (
              <div>
                <textarea
                  className="form-control"
                  value={moderatorNote}
                  onChange={handleModeratorNoteChange}
                  placeholder="Mesajınızı buraya yazın..."
                />

                <div className="mt-2">
                  <span className="lead">Hazır Mesajlar</span>
                  <div>
                    <button
                      className="btn btn-secondary w-auto mb-2"
                      onClick={() =>
                        setModeratorNote(
                          `"${advert.title}" adlı ilanınız yayına alınmıştır. ${process.env.REACT_APP_PUBLIC_URL}ilan/${advert.slug} adersinden ilanınızı görüntüleyebilirsiniz`
                        )
                      }
                      style={{
                        display: "flex",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        {`"${advert.title}" adlı ilanınız yayına alınmıştır. ${process.env.REACT_APP_PUBLIC_URL}ilan/${advert.slug} adersinden ilanınızı görüntüleyebilirsiniz`}
                      </p>
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn w-auto btn-secondary"
                      onClick={() =>
                        setModeratorNote(
                          `"${advert.title}" adlı ilanınız ... sebepten dolayı yayınlanmamıştır. Lütfen yeniden ilan oluşturunuz.`
                        )
                      }
                      style={{
                        display: "flex",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        {`"${advert.title}" adlı ilanınız ... sebepten dolayı yayınlanmamıştır. Lütfen yeniden ilan oluşturunuz.`}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <button type="submit" className="site-button" onClick={handleSave}>
            Kaydet
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}
export default AdminAdvertDetail;
