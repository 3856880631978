import React, { useState, useEffect, useRef } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as initialRedux from "modules/Initial/_redux/initialRedux";
import * as advertEndpoint from "modules/Advert/_redux/advertEndpoint";
import AdvertCard from "./AdvertCard";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function AdvertSection(props) {
  const myRef = useRef(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const { adverts, randomAdverts, categories } = useSelector(
    (state) => ({
      adverts: state.advert.adverts,
      randomAdverts: state.advert.randomAdverts,
      categories: state.category.categories,
    }),
    shallowEqual
  );

  const [width, setWidth] = useState(window.innerWidth);
  // const [limitParams, setLimitParams] = useQueryParam("limit", NumberParam);
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 12),
    sortBy: withDefault(StringParam, "desc"),
  });

  function nextQuery(data) {
    myRef.current.scrollIntoView();
    setQuery((querys) => ({
      page: querys.page + 1,
    }));
  }
  function backQuery(data) {
    myRef.current.scrollIntoView();
    setQuery((querys) => ({
      page: querys.page - 1,
    }));
  }

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    props.openPace();

    advertEndpoint
      .getAdvertsWithPaginate(query.page, query.limit, query.sortBy)
      .then((res) => res.data)
      .then((adverts) => {
        props.setAdverts(adverts);
        setLoading(false);
        props.closePace();
      });
  }, [query.limit, query.page, query.sortBy]);

  return (
    <div className="section-full bg-white content-inner-2 section-full-2 pb-2">
      <div className="container">
        {randomAdverts.length > 0 ? (
          <>
            <div className="d-flex">
              <div className="mr-auto">
                <h2>İlanlar</h2>
              </div>
            </div>
            <div className="col-lg-12 p-0">
              <div
                className="row post-job-bx browse-job"
                style={{ padding: 0 }}
              >
                {randomAdverts?.map((item, index) => (
                  <AdvertCard
                    advert={{
                      ...item,
                      images: `https://point.alicilar.com/upload/ai/resized/${item.images[0].image}`,
                    }}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </>
        ) : null}

        <div className="d-flex job-title-bx section-head mt-1">
          <div className="col-md-12 p-0" ref={myRef}>
            <div className="mr-auto">
              <h2 className="m-b5">En Güncel İlanlar</h2>
              <h6 className="fw4 m-b0">İnteraktif ilan listesi</h6>
            </div>
          </div>
          {/*  <div className="align-self-end">
            <Link to={"/browse-job"} className="site-button button-sm">
              Kategoriler <i className="fa fa-long-arrow-right"></i>
            </Link>
          </div>*/}
        </div>

        <div className="row mb-5">
          <div className="col-lg-3">
            <div className="sticky-top">
              {/*<div className="quote-bx mb-3">*/}
              {/*  <div className="quote-info">*/}
              {/*    <h4>Binlerce kişiye anında ulaş!</h4>*/}
              {/*    <p>*/}
              {/*      Satıcıların sizi bulması için ihtiyacınız olan her şeyin*/}
              {/*      ilanını hızlı bir şekilde verebilirsiniz.{" "}*/}
              {/*    </p>*/}
              {/*    <Link to={"/ilanOlustur"} className="site-button">*/}
              {/*      Ücretsiz İlan Oluştur*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className="candidates-are-sys m-b30">*/}
              {/*  {categories.map((item, index) => [*/}
              {/*    <Link to={generateCategoryDetail(item.slug)} key={index}>*/}
              {/*      <div*/}
              {/*        style={{*/}
              {/*          fontSize: 20,*/}
              {/*          padding: 5,*/}
              {/*          backgroundColor: "#e7e7e7",*/}
              {/*          borderRadius: 5,*/}
              {/*          marginBottom: 5,*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <div style={{ padding: 5 }}>*/}
              {/*          <i className="fa fa-home" />*/}
              {/*          <span style={{ marginLeft: 5 }}>{item.name}</span>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </Link>,*/}
              {/*    item.hierarchy.map((lev2, indexa) => (*/}
              {/*      <div*/}
              {/*        key={indexa}*/}
              {/*        style={{*/}
              {/*          fontSize: 20,*/}
              {/*          marginLeft: 10,*/}
              {/*          padding: 5,*/}
              {/*          backgroundColor: "#f3f3f3",*/}
              {/*          borderRadius: 5,*/}
              {/*          marginBottom: 5,*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        {lev2.name}*/}
              {/*      </div>*/}
              {/*    )),*/}
              {/*  ])}*/}
              {/*</div>*/}
            </div>
          </div>

          <div className="col-lg-12 p-0">
            <div className="row post-job-bx browse-job" style={{ padding: 0 }}>
              {adverts.results?.map((item, index) => (
                <AdvertCard advert={item} key={index} />
              ))}
            </div>
            <div className="m-t30 mb-4">
              <div className="d-flex col justify-content-between">
                <div>
                  {adverts.page !== 1 && (
                    <button
                      disabled={query.page === 1}
                      className="site-button button-sm mr-auto"
                      onClick={() => backQuery()}
                    >
                      <i className="ti-arrow-left" /> Geri
                    </button>
                  )}
                </div>
                <div>
                  {adverts.page !== adverts.totalPages && (
                    <button
                      className="site-button button-sm"
                      onClick={() => nextQuery()}
                    >
                      İleri <i className="ti-arrow-right" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="d-flex justify-content-between flex-wrap">*/}
        {/*  <EditorCard />*/}
        {/*  <EditorCard />*/}
        {/*  <EditorCard />*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default connect(null, {
  ...advertRedux.actions,
  ...initialRedux.actions,
})(AdvertSection);
