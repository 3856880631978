import React from "react";
import SelectSection from "./parts/filters/SelectSection";
import RadioSection from "./parts/filters/RadioSection";
import MultiSelectSection from "./parts/filters/MultiSelectSection";

const getFilterSection = (filter) => ({
  multiSelect: <MultiSelectSection filter={filter} key={filter.slug} />,
  singleSelect: <SelectSection filter={filter} key={filter.slug} />,
  multiCheck: <RadioSection filter={filter} key={filter.slug} />,
});

const FilterSidebar = (props) => {
  return props.filters.map(
    (filter) => getFilterSection(filter)[filter.filterType]
  );
};

export default FilterSidebar;
