import PaidAdvertContactPage from "../PaidAdvertContactPage";

const PaidAdvertsRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/reklam/iletisim",
      component: PaidAdvertContactPage,
    },
  ],
};

export default PaidAdvertsRoute;
