import { hasPermission } from "helpers";
import AppContext from "AppContext";
import React, { Component } from "react";
import { connect } from "react-redux";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";
import JWTService from "./JWTService";

class AuthControl extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes,
    };
  }

  componentDidMount() {
    if (this.props.userRole === "temporary") {
      this.smsOtpVerification();
    }

    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, userRole } = props;
    const { pathname } = location;

    const matched = matchRoutes(state.routes, pathname)[0];

    if (matched.match.path == "/checkout") {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get("accessToken");
      const refreshToken = urlParams.get("refreshToken");

      if (accessToken && refreshToken) {
        JWTService.setSession(accessToken, refreshToken);
        JWTService.checkToken()
          .then(() => {
            window.location.replace(`${window.location.origin}/checkout`);
          })
          .catch(() => {
            window.location.replace(`${window.location.origin}/login`);
          });
      } else {
        return {
          accessGranted: matched
            ? hasPermission(matched.route.auth, userRole)
            : true,
        };
      }
    } else {
      return {
        accessGranted: matched
          ? hasPermission(matched.route.auth, userRole)
          : true,
      };
    }
  }

  smsOtpVerification() {
    const { location, history } = this.props;
    const { pathname } = location;

    history.push({
      pathname: "/otp",
      state: { redirectUrl: pathname },
    });
  }

  redirectRoute() {
    const { location, userRole, history } = this.props;
    const { pathname, state } = location;
    const redirectUrl = state && state.redirectUrl ? state.redirectUrl : "/";

    /*
        User is guest
        Redirect to Login Page
        */
    if (!userRole || userRole.length === 0) {
      history.push({
        pathname: "/login",
        state: { redirectUrl: pathname },
      });
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
      history.push({
        pathname: redirectUrl,
      });
    }
  }

  render() {
    return this.state.accessGranted ? <>{this.props.children}</> : null;
  }
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role,
  };
}

AuthControl.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(AuthControl));
