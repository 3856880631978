import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

const AdminSettingsNewPassword = (props) => {
  const { state, handleChange } = props;
  const [values, setValues] = useState({
    showCurrentPassword: false,
    showNewPassword: false,
    showReNewPassword: false,
  });

  const handleClickShowCurrentPassword = () => {
    setValues({ showCurrentPassword: !values.showCurrentPassword });
  };

  const handleClickShowNewPassword = () => {
    setValues({ showNewPassword: !values.showNewPassword });
  };

  const handleClickShowReNewPassword = () => {
    setValues({ showReNewPassword: !values.showReNewPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="d-flex flex-column mb-4">
        <FormControl className="admin-settings-input-container">
          <div className="admin-settings-text-input-container">
            <InputLabel htmlFor="standard-adornment-password">
              Mevcut Şifre
            </InputLabel>
            <Input
              name="password"
              type={values.showCurrentPassword ? "text" : "password"}
              id="standard-basic"
              className="admin-settings-text-input"
              value={state.password}
              label="Mevcut Şifre"
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showCurrentPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </FormControl>
      </div>
      <div className="d-flex flex-column mb-4">
        <FormControl className="admin-settings-input-container">
          <div className="admin-settings-text-input-container">
            <InputLabel htmlFor="standard-adornment-password">
              Yeni Şifre
            </InputLabel>
            <Input
              name="newPassword"
              type={values.showNewPassword ? "text" : "password"}
              id="standard-basic"
              className="admin-settings-text-input"
              value={state.newPassword}
              label="Yeni Şifre"
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </FormControl>
      </div>
      <div className="d-flex flex-column mb-4">
        <FormControl className="admin-settings-input-container">
          <div className="admin-settings-text-input-container">
            <InputLabel htmlFor="standard-adornment-password">
              Yeni Şifre (Tekrar)
            </InputLabel>
            <Input
              name="reNewPassword"
              type={values.showReNewPassword ? "text" : "password"}
              id="standard-basic"
              className="admin-settings-text-input"
              value={state.reNewPassword}
              label="Yeni Şifre (Tekrar)"
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowReNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showReNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </FormControl>
      </div>
    </>
  );
};

export default AdminSettingsNewPassword;
