import React from "react";
import { Link } from "react-router-dom";
import { generateAdvertUrl } from "../../../../helpers";
import AdminLayout from "../../../../markup/Layout/AdminLayout";

const AdminUserOrders = (props) => {
  const { orders } = props;
  return (
    <div className="row">
      <div style={{ width: "100%", overflow: "auto" }}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fiyat</th>
              <th scope="col">Bakiye</th>
              <th scope="col">Durum</th>
              <th scope="col">Kredi Kartı</th>
              <th scope="col">Apple Ödeme</th>
              <th scope="col">Hediye Çeki</th>
              <th scope="col">Satın Alma Tarihi</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((item, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{item.items[0]?.price} TL</td>
                <td>{item.acoin}</td>
                <td>{item.completed ? "Tamamlandı" : "İptal oldu"}</td>
                <td>{item.iyzicoResponse ? "✔️" : "-"}</td>
                <td>{item.appleResponse ? "✔️" : "-"}</td>
                <td>{item.campaignResponse ? "✔️" : "-"}</td>
                <td>{item.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminUserOrders;
