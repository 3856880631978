import React from "react";
import { Link } from "react-router-dom";
import { generateAdvertUrl } from "../../../../helpers";
import AdminLayout from "../../../../markup/Layout/AdminLayout";

const AdminUserAdverts = (props) => {
  const { adverts } = props;
  return (
    <div className="row">
      <div style={{ width: "100%", overflow: "auto" }}>
        <table className="table ">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Başlık</th>
              <th scope="col">Bakiye Değeri</th>
              <th scope="col">İlan Konumu</th>
              <th scope="col">Oluşturma Tarihi</th>
            </tr>
          </thead>
          <tbody>
            {adverts.map((item, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>
                  <Link to={generateAdvertUrl(item.slug)} target="_blank">
                    {item.title}
                  </Link>
                </td>
                <td>{item.acoin}</td>
                <td>
                  {item.location.province.name} / {item.location.district.name}
                </td>
                <td>{item.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminUserAdverts;
