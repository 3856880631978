import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as auth from "modules/Auth/_redux/authRedux";
import { info } from "modules/Auth/_redux/authCrud";
import JWTService from "@core/auth/JWTService";
//import PhoneField from "./parts/PhoneField";
import SocialBar from "./parts/SocialBar";
let bnr = require("images/background/bg6.jpg");

function Register(props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
  });

  function registerLocal(e) {
    e.preventDefault();
    setLoading(true);
    JWTService.createUser(form)
      .then((userInformation) => {
        props.registerAction(userInformation);
      })
      .then((result) => {
        info().then((res) => props.setBalance(res.data.balance));
        //props.setUserData(result)
      })
      .then((result) => {
        props.history.push(`/otp`);
      })
      .catch((err) => {
        //toast.error(err.response.data.message);
        setLoading(false);
      });
  }

  function handleChange(event) {
    event.persist();
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  // function handleChangePhone(event) {
  //   setForm((prevState) => ({
  //     ...prevState,
  //     phone: event,
  //   }));
  // }

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div className="bg-img-fix" style={{ height: "100vh" }}>
          <div
            className="row d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="col-xl-4 col-lg-8 col-md-8 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
              <div className="login-form style-2 d-flex flex-column justify-content-between  ">
                <div className="logo-header text-center p-tb30">
                  <Link to={"./"}>
                    <img src={require("images/alicilar-logo.png")} alt="" />
                  </Link>
                </div>
                <div className="clearfix" />
                <div className="tab-content nav p-b30 tab">
                  <div id="login" className="tab-pane active ">
                    <form className=" dez-form p-b30">
                      <h5 className="form-title m-t0">Yeni üye kaydı</h5>
                      <div className="dez-separator-outer m-b5">
                        <div className="dez-separator bg-primary style-liner" />
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <input
                            name="name"
                            autoFocus
                            required
                            className="form-control"
                            value={form.name}
                            onChange={handleChange}
                            placeholder="Ad"
                            type="text"
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <input
                            name="surname"
                            required
                            className="form-control"
                            value={form.surname}
                            onChange={handleChange}
                            placeholder="Soyad"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          name="email"
                          required
                          className="form-control"
                          value={form.email}
                          onChange={handleChange}
                          placeholder="E-Posta"
                          type="email"
                        />
                        {/*<PhoneField
                          value={form.phone}
                          onChange={(e) => handleChange(e)}
                        />*/}
                      </div>

                      <div className="form-group">
                        <input
                          name="password"
                          required=""
                          className="form-control "
                          value={form.password}
                          onChange={handleChange}
                          placeholder="Şifre"
                          type="password"
                        />
                      </div>

                      <div className="form-group text-left">
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="site-button dz-xs-flex m-r5"
                            disabled={loading}
                            onClick={registerLocal}
                          >
                            {loading ? "Yükleniyor.." : "Üye Ol"}
                          </button>
                          <span className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="check1"
                              name="example1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="check1"
                            >
                              Beni Hatırla
                            </label>
                          </span>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <Link
                            data-toggle="tab"
                            to="/login"
                            className="forget-pass m-l5"
                          >
                            <i className="fa fa-sign-in" /> Giriş Yap
                          </Link>
                          <Link
                            data-toggle="tab"
                            to="/forgotPassword"
                            className="forget-pass m-l5"
                          >
                            <i className="fa fa-unlock-alt" /> Şifremi Unuttum
                          </Link>
                        </div>
                      </div>
                      <SocialBar />
                    </form>
                  </div>
                </div>
                <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                  <div className="col-lg-12 text-center">
                    <span>
                      © Copyright by{" "}
                      <Link to={"/"}>
                        Alıcılar Bilgi Teknolojileri Pazarlama ve Ticaret A.Ş.{" "}
                      </Link>{" "}
                      All rights reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(Register);
