import SearchList from "../SearchList";

const SearchPublicRoute = {
  routes: [
    {
      path: "/ara",
      component: SearchList,
    },
  ],
};

export default SearchPublicRoute;
