import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "modules/Auth/_redux/authRedux";
import * as advert from "modules/Advert/_redux/advertRedux";
import * as initial from "modules/Initial/_redux/initialRedux";
import * as category from "modules/Category/_redux/categoryRedux";
import * as admin from "modules/Admin/_redux/adminRedux";
import * as page from "modules/Page/_redux/pageRedux";
import * as messages from "modules/Auth/_redux/messageRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  admin: admin.reducer,
  category: category.reducer,
  advert: advert.reducer,
  initial: initial.reducer,
  page: page.reducer,
  messages: messages.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
