import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Profilesidebar from "markup/Element/Profilesidebar";
import { connect } from "react-redux";
import * as auth from "modules/Auth/_redux/authRedux";
import * as authCrud from "modules/Auth/_redux/authCrud";
import UserProfileLayout from "markup/Layout/UserProfileLayout";
import PhoneField from "../parts/PhoneField";
import { toast } from "react-toastify";
import { isValidTurkishPhoneNumber } from "helpers/PhoneFormats";

function MyProfile(props) {
  const [profile, setProfile] = useState({
    name: "",
    surname: "",
    phone: "",
    phone2: "",
    email: "",
  });

  useEffect(() => {
    setProfile({ ...props.auth.user });

    authCrud
      .info()
      .then((res) => res.data)
      .then((result) => {
        setProfile(result.user);
      });
  }, []);

  function handleChange(e) {
    e.persist();

    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    let newUserData = {
      name: profile.name,
      surname: profile.surname,
      email: profile.email,
    };

    if (profile.phone) {
      const isValid = isValidTurkishPhoneNumber(profile.phone);
      if (!isValid)
        return toast.error("Lütfen geçerli bir telefon numarası girin");

      newUserData["phone"] = profile.phone;
    }

    authCrud
      .updateUser(profile.id, newUserData)
      .then((res) => res.data)
      .then((data) => {
        props.setUser(data);

        toast.success("Bilgileriniz güncellendi");
      });
  }

  return (
    <UserProfileLayout {...props}>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar {...props} />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Üyelik Bilgileriniz
                      </h5>
                      <Link
                        to={"./"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Anasayfa
                      </Link>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row m-b30">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Ad:</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Ad"
                              onChange={(e) => handleChange(e)}
                              value={profile.name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Soyad:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Soyad"
                              name="surname"
                              onChange={(e) => handleChange(e)}
                              value={profile.surname}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>GSM:</label>

                            <PhoneField
                              value={profile.phone}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Mail:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="E-Posta"
                              name="email"
                              onChange={(e) => handleChange(e)}
                              value={profile.email}
                            />
                          </div>
                        </div>
                      </div>

                      {/*<div className="job-bx-title clearfix">
                        <h5 className="font-weight-700 pull-left text-uppercase">
                          Ulaşım Bilgileriniz
                        </h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Görünen GSM:</label>
                            <p>
                              Yayınladığınız alıcı ilanlarından size ulaşmak
                              isteyen kişilerin göreceği numarayı buradan
                              belirleyebilirsiniz. Eğer pasif olursa üyelik
                              telefon numaranız paylaşılacaktır.
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="phone2"
                              placeholder="905547997414"
                              onChange={(e) => handleChange}
                              value={profile.phone2}
                            />
                          </div>
                        </div>
                      </div>*/}

                      <button className="site-button m-b30">Kaydet</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default connect(auth.reducer, auth.actions)(MyProfile);
