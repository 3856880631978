import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import JWTService from "@core/auth/JWTService";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetBalance: "[User] Set Balance",

  // Provider
  GoogleProviderSign: "[Provider] Google Login Receive",
};

const initialAuthState = {
  user: {},
  userBalance: 0,
  isLogged: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v713-alicilar-auth",
    whitelist: ["user", "authToken", "userBalance", "isLogged"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { data } = action.payload;
        return { user: { ...data }, isLogged: true };
      }

      case actionTypes.Register: {
        const { data } = action.payload;
        return { user: { ...data }, isLogged: true };
      }

      case actionTypes.GoogleProviderSign: {
        const { data } = action.payload;
        return { user: { ...data }, isLogged: true };
      }

      case actionTypes.Logout: {
        JWTService.logout();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetBalance: {
        const { balance } = action.payload;
        return { ...state, userBalance: balance };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (data) => ({
    type: actionTypes.Login,
    payload: { data },
  }),
  registerAction: (data) => ({
    type: actionTypes.Register,
    payload: { data },
  }),
  googleLogin: (data) => ({
    type: actionTypes.GoogleProviderSign,
    payload: { data },
  }),
  getMe: (user) => ({
    type: actionTypes.SetUser,
    payload: { user },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setBalance: (balance) => ({
    type: actionTypes.SetBalance,
    payload: { balance },
  }),
};

export function* saga() {
  /*yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const {data: user} = yield getUserByToken();

        yield put(actions.fulfillUser(user));
    });*/
}
