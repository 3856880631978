import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as categoryRedux from "modules/Category/_redux/categoryRedux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";

const RadioSection = (props) => {
  const handleFilterChange = (selecteds, parentFilter, value) => {
    if (selecteds.target.checked) {
      props.addFilter({ parent: parentFilter, value });
    } else {
      props.removeFilter({ parent: parentFilter, value });
    }
  };

  return (
    <aside
      id={props.filter.slug}
      key={props.filter.slug}
      className=" sidebar-filter bg-white mt-3 mr-2"
    >
      {props.filter.title == "Filtre" ? null : (
        <h6 className="acod-title">
          <div className="sidebar-filter-title">{props.filter.title}</div>
        </h6>
      )}

      <div className="d-flex justify-content-center align-items-center">
        {props.filter.values.map((val, index) => (
          <div
            key={index}
            className="custom-control custom-checkbox ml-3 d-flex align-items-center"
          >
            <input
              className="custom-control-input"
              id={val}
              onChange={(e) => handleFilterChange(e, props.filter.slug, val)}
              type="checkbox"
            />
            <label className="custom-control-label small" htmlFor={val}>
              {val}
            </label>
          </div>
        ))}
      </div>
    </aside>
  );
};

export default connect(null, {
  ...categoryRedux.actions,
  ...advertRedux.actions,
})(RadioSection);
