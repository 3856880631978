import React from "react";
import { Redirect } from "react-router-dom";
import AuthRoute from "modules/Auth/routes/AuthRoute";
import { generateRoutesFromConfigs } from "helpers";
import AdvertPublicRoute from "modules/Advert/routes/AdvertPublicRoute";
import AdvertPrivateRoute from "modules/Advert/routes/AdvertPrivateRoute";
import ProfileRoute from "modules/Auth/routes/ProfileRoute";
import CheckoutPrivateRoute from "modules/Checkout/routes/CheckoutPrivateRoute";
import CategoryPublicRoute from "modules/Category/routes/CategoryPublicRoute";
import SearchPublicRoute from "modules/Search/routes/SearchPublicRoute";
import MainPublicRoute from "modules/Main/routes/MainPublicRoute";
import AdminPrivateRoute from "modules/Admin/routes/AdminPrivateRoute";
import SmsRoute from "modules/Auth/routes/SmsRoute";
import PagePublicRoute from "modules/Page/routes/PagePublicRoute";
import ContactRoute from "../modules/Contact/routes/ContactRoute";
import PaidAdvertsRoute from "modules/PaidAdverts/routes/PaidAdvertsRoute";

const routeConfigs = [
  // Paid Adverts
  PaidAdvertsRoute,

  // Page
  PagePublicRoute,

  // Sms Otp
  SmsRoute,

  // Advert
  AdvertPublicRoute,
  AdvertPrivateRoute,

  //Contact
  ContactRoute,

  // Buy acoin
  CheckoutPrivateRoute,

  // Category
  CategoryPublicRoute,

  // Search
  SearchPublicRoute,

  // Auth and custom
  AuthRoute,
  ProfileRoute,

  // Admin private
  AdminPrivateRoute,

  // Main Homepage - it should be at the end
  MainPublicRoute,
];

const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    component: () => <Redirect to="/example" />,
  },
];

export default routes;
