import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import { connect, shallowEqual, useSelector } from "react-redux";

function FLMultiSelect(props) {
  const { filters } = useSelector((state) => ({
    filters: state.advert.newAdvert.filters,
  }));

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.editNewAdvertFilters(name, value);
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth={true} margin="dense">
        <InputLabel id={props.filter.slug}>{props.filter.title}</InputLabel>
        <Select
          multiple
          name={props.filter.slug}
          labelId={props.filter.slug}
          id={props.filter.slug}
          value={filters[props.filter.slug] ? filters[props.filter.slug] : []}
          onChange={handleChange}
        >
          {props.filter.values.map((item, index) => (
            <MenuItem
              value={item}
              key={index}
              style={{
                backgroundColor: filters[props.filter.slug]?.includes(item)
                  ? "#11986e"
                  : "white",
                color: filters[props.filter.slug]?.includes(item)
                  ? "#efefef"
                  : "#222",
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default connect(null, { ...advertRedux.actions })(FLMultiSelect);
