import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as auth from "modules/Auth/_redux/authRedux";

function Profilesidebar(props) {
  const { auth } = props;

  const [subAdverts, showSubAdverts] = useState(false);

  function logout(e) {
    e.preventDefault();
    props.logout();
    props.history.push(`/`);
  }

  return (
    <div className="col-xl-3 col-lg-4 m-b30">
      <div className="sticky-top" style={{ top: "83px" }}>
        <div className="candidate-info">
          <div className="candidate-detail text-center">
            <div className="candidate-title">
              <div className="">
                <h5 className="m-b5">{auth.user.name}</h5>
                <p className="m-b0">{auth.user.phone}</p>
              </div>
            </div>
          </div>
          <ul>
            <li>
              <Link
                to={"/my-profile"}
                className={props.match.path === "/my-profile" ? "active" : null}
              >
                <i className="fa fa-user-o" aria-hidden="true" />
                <span>Profil</span>
              </Link>
            </li>
            <li>
              <Link
                to={"/my-credits"}
                className={props.match.path === "/my-credits" ? "active" : null}
              >
                <i className="fa fa-file-text-o" aria-hidden="true" />
                <span>Kredi</span>
              </Link>
            </li>

            <li>
              <Link
                to={"/my-boughts"}
                className={props.match.path === "/my-boughts" ? "active" : null}
              >
                <i className="fa fa-credit-card" />
                <span>Satın Aldıklarım</span>
              </Link>
            </li>
            <li>
              <Link
                to={"/my-campaign"}
                className={
                  props.match.path === "/my-campaign" ? "active" : null
                }
              >
                <i className="fa fa-gift" />
                <span>Hediye Çeki</span>
              </Link>
            </li>

            <li>
              <Link
                className={
                  props.match.path === "/my-adverts" ||
                  props.match.path === "/my-timedout-adverts"
                    ? "active"
                    : null
                }
                onClick={() => showSubAdverts(!subAdverts)}
                to={"/my-adverts"}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <i className="fa fa-address-book" aria-hidden="true" />
                    <span>İlanlarım</span>
                  </div>
                  {/*<div>*/}
                  {/*  {!subAdverts && (*/}
                  {/*    <i*/}
                  {/*      className="fa fa-chevron-down"*/}
                  {/*      style={{ fontSize: "12px" }}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*  {subAdverts && (*/}
                  {/*    <i*/}
                  {/*      className="fa fa-chevron-up"*/}
                  {/*      style={{ fontSize: "12px" }}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*</div>*/}
                </div>
              </Link>
              {/*{subAdverts && (*/}
              <ul className="subAdverts-list">
                <li className="subAdverts-item">
                  <Link to={"/my-adverts"}>
                    {" "}
                    <i
                      className={
                        props.match.path === "/my-adverts"
                          ? "fa fa-bullhorn activeListIcon"
                          : "fa fa-bullhorn"
                      }
                    />
                    <span
                      className={
                        props.match.path === "/my-adverts"
                          ? "subAdverts-content active"
                          : "subAdverts-content"
                      }
                    >
                      Onaylanan İlanlar
                    </span>
                  </Link>
                </li>
                <li className="subAdverts-item">
                  <Link to={"/my-timedout-adverts"}>
                    {" "}
                    <i
                      className={
                        props.match.path === "/my-timedout-adverts"
                          ? "fa fa-bullhorn activeListIcon"
                          : "fa fa-bullhorn"
                      }
                    />
                    <span
                      className={
                        props.match.path === "/my-timedout-adverts"
                          ? "subAdverts-content active"
                          : "subAdverts-content"
                      }
                    >
                      Onay Bekleyen İlanlar
                    </span>
                  </Link>
                </li>
              </ul>
              {/* )}*/}
            </li>

            <li>
              <Link
                to={"/my-password"}
                className={
                  props.match.path === "/my-passwords" ? "active" : null
                }
              >
                <i className="fa fa-key" aria-hidden="true" />
                <span>Şifre Değiştir</span>
              </Link>
            </li>
            <li>
              <a href="/" onClick={logout}>
                <i className="fa fa-sign-out" aria-hidden="true" />
                <span>Çıkış</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default connect(auth.reducer, auth.actions)(Profilesidebar);
