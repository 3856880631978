import axios from "axios";

const GET_CHATROOMS_URL = "v1/message/getChatrooms";
const CHECK_CHATROOM_EXIST = "v1/message/chatroomCheck";
const SEND_MESSAGE = "v1/message/send";
const DELETE_MESSAGE = "v1/message/delete";
const GET_MESSAGES_OF_CHATROOM = "v1/message/messages";
const SET_MESSAGES_AS_READ = "v1/message/seen";

export function getChatrooms() {
  return axios.get(GET_CHATROOMS_URL);
}

export function checkChatroom(id, advertSlug) {
  return axios.get(`${CHECK_CHATROOM_EXIST}/${id}?advert=${advertSlug}`);
}

export function sendMessage(payload) {
  return axios.post(SEND_MESSAGE, payload);
}

export function deleteMessageAPIEndpoint(payload) {
  return axios.post(DELETE_MESSAGE, payload);
}

export function getMessagesOfChatroom(chatroomId, date) {
  return axios.get(
    `${GET_MESSAGES_OF_CHATROOM}/${chatroomId}?startDate=${date}`
  );
}

export function setMessagesAsSeen(messageIds) {
  return axios.put(SET_MESSAGES_AS_READ, { messageIds });
}
