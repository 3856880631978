import CheckoutPage from "../CheckoutPage";
import AuthRoles from "@core/auth/AuthRoles";

const CheckoutPrivateRoute = {
  auth: AuthRoles.user,
  routes: [
    {
      path: "/checkout",
      component: CheckoutPage,
    },
  ],
};

export default CheckoutPrivateRoute;
