import axios from "axios";

export const GET_CATEGORIES = "v1/categories";

export function getCategory(categorySlug, filters) {
  return axios.post(`${GET_CATEGORIES}/${categorySlug}?relation=category`, {
    filters,
  });
}

export function getCategoryPaginate(categorySlug, filters, page) {
  return axios.post(
    `${GET_CATEGORIES}/${categorySlug}?relation=category&page=${page}`,
    {
      filters,
    }
  );
}

export function getCategories() {
  return axios.get(GET_CATEGORIES);
}
