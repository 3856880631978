import AuthRoles from "@core/auth/AuthRoles";
import CreateAdvert from "../views/CreateAdvert";

const AdvertPrivateRoute = {
  auth: AuthRoles.user,
  routes: [
    {
      path: "/ilanOlustur",
      component: CreateAdvert,
    },
  ],
};

export default AdvertPrivateRoute;
