import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

function CategoryCreateDialog(props) {
  const [categoryType, setCategoryType] = useState("root");
  const [category, setCategory] = useState("");

  const changeNewCategoryType = (e) => {
    setCategoryType(e.target.value);
    props.handleFieldChange(e);
  };

  const handleChange = (e) => {
    setCategory(e.target.value);
    props.handleFieldChange(e);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Dialog open={props.open} onClose={props.toggleCreateCategoryDialog}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.toggleCreateCategoryDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          <Typography variant="h4">Yeni Kategori</Typography>
          <TextField
            onChange={handleChange}
            label="Kategori Başlık"
            variant="outlined"
            name="name"
          />

          <RadioGroup
            row
            aria-label="position"
            name="categoryType"
            defaultValue="root"
            onChange={(e) => changeNewCategoryType(e)}
          >
            <FormControlLabel
              value="root"
              control={<Radio color="primary" />}
              label="Root"
              labelPlacement="start"
            />
            <FormControlLabel
              value="child"
              control={<Radio color="primary" />}
              label="Child"
              labelPlacement="start"
            />
          </RadioGroup>

          {categoryType === "child" && (
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="parentCategoryId">Kategori</InputLabel>
              <Select
                labelId="parentCategoryId"
                id="parentCategoryId"
                label="Kategori"
                name="parentCategoryId"
                value={category}
                onChange={(e) => handleChange(e)}
              >
                {props.levelOneCategories.map((e, index) => (
                  <MenuItem key={index} value={e._id}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            onChange={(e) => props.handleFieldChange(e)}
            label="Kısa Açıklama"
            variant="outlined"
            multiline
            rows={3}
            name="body"
          />

          <InputLabel shrink>İkon </InputLabel>

          <input
            type="file"
            name="image"
            onChange={(e) => props.handleImageSelect(e)}
          />

          <div>
            <Button
              variant="contained"
              onClick={(e) => props.postCreateCategory(e)}
            >
              Kaydet
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default CategoryCreateDialog;
