import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LayoutV1 from "markup/Layout/LayoutV1";
import * as advertEndpoint from "modules/Advert/_redux/advertEndpoint";
import * as categoryRedux from "modules/Category/_redux/categoryRedux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import { connect, shallowEqual, useSelector } from "react-redux";
import FilterSidebar from "./FilterSidebar";
import AdvertCard from "../Main/parts/AdvertCard";
import { Helmet } from "react-helmet";
import DrawerMenu from "./parts/filters/mobileSections/DrawerMenu";
import { makeStyles } from "@material-ui/core/styles";

//let bnr = require("images/banner/bnr1.jpg");

function CityCategory(props) {
  const { filters, advertsOfCity } = useSelector(
    (state) => ({
      filters: state.category.categoryDetail.filters,
      advertsOfCity: state.advert.advertsOfCity,
    }),
    shallowEqual
  );
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const [city, setCity] = useState({
    name: "",
    districts: [],
  });

  useEffect(() => {
    let cityNo = props.match.params.cityNo;

    advertEndpoint
      .getAdvertsOfCityWithPaginate(1, 50, "desc", cityNo)
      .then((res) => res.data)
      .then((result) => {
        props.setAdvertsOfCity({ results: result.adverts.results });
        setCity(result.city);
      });
  }, [props.selectedFilter]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      // props.resetFilter();
    };
  }, []);

  const { categoryDetail, adverts } = props;

  return (
    <LayoutV1>
      <Helmet>
        <title>Alıcılar - {city.name} Konumlu ilanlar</title>
        <meta name="description" content={categoryDetail.body} />
      </Helmet>
      <div className="page-content bg-white">
        <div
          className="dez-bnr-inr "
          style={{
            backgroundColor: "#fbfbfb",
            boxShadow: "rgb(0 0 0 / 6%) 0px 0px 10px 0px",
          }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-black">{city.name}</h1>
            </div>
          </div>
        </div>

        <div className="content-block">
          <div className="section-full browse-job p-b50 ">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <ul
                    className="post-job-bx browse-job-grid row p-0"
                    style={{ padding: "15px" }}
                  >
                    {advertsOfCity.results?.map((item, index) => (
                      <AdvertCard advert={item} key={index} />
                    ))}
                  </ul>

                  {/*<div className="pagination-bx float-right m-t30">
                    <ul className="pagination">
                      <li className="previous">
                        <Link to={""}>
                          <i className="ti-arrow-left"></i> Önceki
                        </Link>
                      </li>
                      <li className="active">
                        <Link to={""}>1</Link>
                      </li>
                      <li>
                        <Link to={""}>2</Link>
                      </li>
                      <li>
                        <Link to={""}>3</Link>
                      </li>
                      <li className="next">
                        <Link to={""}>
                          Sonraki <i className="ti-arrow-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

const useStyles = makeStyles((theme) => ({
  contentStyle: {
    color: "rgba(0, 0, 0, 0.87)",
    padding: "6px 16px",
    minWidth: "64px",
    boxSizing: "border-box",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    fontSize: "10px",
    margin: 0,
  },
}));

const mapStateToProps = (state) => ({
  //items  : state.cartList.cart,
  adverts: state.advert.advertsOfCategory.results,
  categoryDetail: state.category.categoryDetail,
  selectedFilter: state.category.selectedFilter,
});

export default connect(mapStateToProps, {
  ...categoryRedux.actions,
  ...advertRedux.actions,
})(CityCategory);
