import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as initial from "modules/Initial/_redux/initialRedux";
import Badge from "@material-ui/core/Badge";
import Pace from "react-pace-progress";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import HeaderSearchBar from "./HeaderSearchBar";

let credit = require("images/credit1.png");

function Header(props) {
  let history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  const { user, userBalance, paceLoading, isAdmin } = useSelector(
    (state) => ({
      user: state.auth.user,
      userBalance: state.auth.userBalance,
      paceLoading: state.initial.paceLoading,
      isAdmin: state.auth.user.role === "admin",
    }),
    shallowEqual
  );

  const hasUnreadMessage = useSelector(
    (state) => state.messages.hasUnreadMessage
  );

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleSubmit(e) {
    // e.preventDefault();

    if (Number(keyword) >= 180000) {
      history.push(`/ilan/${keyword}`);
    } else {
      history.push(`/ara?kelime=${keyword}`);
    }
  }

  function handleChange(e) {
    setKeyword(e.target.value);
  }

  return (
    <>
      {paceLoading && (
        <Pace
          color="white"
          style={{ zIndex: 9999, position: "fixed", top: 0, marginTop: -5 }}
        />
      )}

      <header className="site-header mo-left header fullwidth ">
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div
            className="main-bar clearfix"
            style={{ backgroundColor: "#11986e" }}
          >
            <div className="header-container clearfix">
              <div className="logo-header mostion ">
                <Link to={"/"}>
                  <img
                    src={require("./../../images/logo-white.png")}
                    className="logo"
                    alt="Alıcılar Logo"
                  />
                </Link>
              </div>

              <div className="mobil-nav-section">
                {/*Mobil menü*/}
                <button
                  className="navbar-toggler collapsed shadow radius-sm"
                  type="button"
                  onClick={() => history.push("/messages")}
                  style={{
                    color: "white",
                    padding: "5px 10px",
                    border: "black",
                    position: "relative",
                  }}
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                >
                  {hasUnreadMessage ? (
                    <div
                      className="unreadDot"
                      style={{
                        position: "absolute",
                        top: -3,
                        right: 0,
                        height: 17,
                        width: 17,
                      }}
                    >
                      !
                    </div>
                  ) : null}
                  <i class="fa" style={{ fontSize: 17 }}>
                    &#xf27a;
                  </i>
                </button>

                {user.name && (
                  <React.Fragment>
                    <div>
                      <Link
                        to={"/checkout"}
                        className="navbar-toggler collapsedshadow radius-sm shadow"
                        style={{
                          color: "white",
                          padding: "5px 10px",
                          border: "black",
                        }}
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                      >
                        <Badge badgeContent={userBalance} color="secondary">
                          <img
                            src={credit}
                            alt=""
                            width={20}
                            style={{ height: 20 }}
                          />
                        </Badge>
                      </Link>

                      <Link
                        to={"/my-profile"}
                        className="navbar-toggler collapsed shadow radius-sm  "
                        style={{
                          color: "white",
                          padding: "5px 10px",
                          border: "black",
                        }}
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                      >
                        <i className="fa fa-user "></i>
                      </Link>
                    </div>
                    <Link
                      to={"/ilanOlustur"}
                      className="navbar-toggler collapsed shadow radius-sm"
                      style={{
                        backgroundColor: "orange",
                        color: "white",
                        padding: "5px 10px",
                        border: "black",
                      }}
                      aria-controls="navbarNavDropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-plus-circle"></i>
                    </Link>
                  </React.Fragment>
                )}
                {!user.name && (
                  <Link
                    to={"/register"}
                    className="navbar-toggler collapsed shadow radius-sm"
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      padding: 5,
                      border: "black",
                    }}
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-plus-circle"></i>
                  </Link>
                )}
              </div>

              {!user.name ? (
                <React.Fragment>
                  <div className="extra-nav">
                    <div className="extra-cell">
                      <Link
                        to={"/register"}
                        className="site-button shadow"
                        style={{ backgroundColor: "orange", borderRadius: 10 }}
                      >
                        <i className="fa fa-plus-circle"></i> Almak İçin İlan
                        Ver
                      </Link>
                    </div>
                  </div>
                  <div className="extra-nav">
                    <div className="extra-cell">
                      <Link
                        to={"/register"}
                        className="site-button shadow"
                        style={{ backgroundColor: "#25ba8b", borderRadius: 10 }}
                      >
                        <i className="fa fa-user"></i> Hesap
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="extra-nav">
                    <div className="extra-cell">
                      <Link
                        to={"/ilanOlustur"}
                        className="site-button shadow"
                        style={{ backgroundColor: "orange", borderRadius: 10 }}
                      >
                        <i className="fa fa-plus-circle"></i> Almak İçin İlan
                        Ver
                      </Link>
                    </div>
                  </div>
                  <div className="extra-nav">
                    <div className="extra-cell">
                      <Link
                        to={"/my-profile"}
                        className="site-button shadow"
                        style={{ backgroundColor: "#25ba8b", borderRadius: 10 }}
                      >
                        <i className="fa fa-user"></i> {user.name}
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {/*<div className="extra-nav">*/}
              {/*  <div className="extra-cell">*/}
              {/*    <button*/}
              {/*      autoFocus*/}
              {/*      onClick={() => props.toggleSidebarModal()}*/}
              {/*      className="site-button "*/}
              {/*      style={{*/}
              {/*        backgroundColor: "#25ba8b",*/}
              {/*        borderRadius: 10,*/}
              {/*        outline: "none",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <i className="fa fa-align-justify"></i>*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {user.name && (
                <React.Fragment>
                  <div className="extra-nav">
                    <div className="extra-cell">
                      <Link
                        autoFocus
                        to={"/checkout"}
                        className="site-button "
                        style={{
                          backgroundColor: "#25ba8b",
                          borderRadius: 10,
                          outline: "none",
                        }}
                      >
                        <Badge badgeContent={userBalance} color="secondary">
                          <img
                            src={credit}
                            alt=""
                            width={25}
                            style={{ height: 25 }}
                          />
                        </Badge>
                      </Link>
                    </div>
                  </div>
                  <div className="extra-nav">
                    <div className="extra-cell">
                      <button
                        autoFocus
                        onClick={() => history.push("/messages")}
                        className="site-button "
                        style={{
                          backgroundColor: "#25ba8b",
                          borderRadius: 10,
                          outline: "none",
                        }}
                      >
                        {hasUnreadMessage ? (
                          <div
                            className="unreadDot"
                            style={{ position: "absolute", top: 0, left: 5 }}
                          >
                            !
                          </div>
                        ) : null}
                        <i class="fa" style={{ fontSize: 20 }}>
                          &#xf27a;
                        </i>
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {isAdmin && (
                <div className="extra-nav">
                  <div className="extra-cell">
                    <Link
                      autoFocus
                      to={"/admin/dashboard"}
                      className="site-button "
                      style={{
                        backgroundColor: "#307db8",
                        borderRadius: 10,
                        outline: "none",
                      }}
                    >
                      <i className="fa fa-cog"></i>
                    </Link>
                  </div>
                </div>
              )}

              {history.location.pathname !== "/" ? (
                <HeaderSearchBar
                  handleSubmit={() => handleSubmit()}
                  handleChange={(e) => handleChange(e)}
                  keyword={keyword}
                />
              ) : (
                scrollPosition > 100 && (
                  <HeaderSearchBar
                    handleSubmit={() => handleSubmit()}
                    handleChange={(e) => handleChange(e)}
                    keyword={keyword}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default connect(null, { ...initial.actions, ...advertRedux.actions })(
  Header
);
