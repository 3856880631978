import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as authRedux from "modules/Auth/_redux/authRedux";
import * as initialRedux from "modules/Initial/_redux/initialRedux";
import AdminLayout from "markup/Layout/AdminLayout";
import MaterialTable from "material-table";
import Box from "@material-ui/core/Box";
import { getDashboard } from "../_redux/adminEndpoint";
import * as adminEndpoint from "../_redux/adminEndpoint";

function AdminDashboard(props) {
  const [dashboard, setDashboard] = useState({
    ordersCount: 0,
    priceTotal: 0,
    usersCount: 0,
    boughtsCount: 0,
  });
  useEffect(() => {
    adminEndpoint.getDashboard().then((res) => {
      console.log({ data: res.data });
      setDashboard({
        ordersCount: res.data.ordersCount,
        usersCount: res.data.usersCount,
        boughtsCount: res.data.boughtsCount,
        priceTotal: res.data.priceTotal,
      });
    });
  }, []);

  const dashoardWidgets = (total, name) => {
    return (
      <Box className="dashboardADM-widgets-box shadow radius-md" p={1} mb={5}>
        <div className="row">
          <div className="col-xs-12 col-md-12 dashboardADM-widgets-contents-container">
            <div className="col-xs-12 col-md-12">
              <label className="dashboardADM-widgets-number">{total}</label>
            </div>
            <div className="col-xs-12 col-md-12" style={{ padding: 0 }}>
              <label className="dashboardADM-widgets-header">{name}</label>
            </div>
          </div>
        </div>
      </Box>
    );
  };

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-xs-12 col-md-3">
          {dashoardWidgets(dashboard.ordersCount, "Satışlar")}
        </div>
        <div className="col-xs-12 col-md-3">
          {dashoardWidgets(dashboard.usersCount, "Üye Sayısı")}
        </div>
        <div className="col-xs-12 col-md-3">
          {dashoardWidgets(dashboard.boughtsCount, "Açılan İlan ")}
        </div>
        <div className="col-xs-12 col-md-3">
          {dashoardWidgets(`${dashboard.priceTotal} TL`, "Toplam Tutar")}
        </div>
        <div className="col-xs-12 col-md-12" style={{ marginBottom: "12m" }}>
          {/*

          <MaterialTable
            title="Kategoriler"
            options={{
              search: false,
            }}
            actions={[
              {
                icon: "add",
                tooltip: "Add Category",
                isFreeAction: true,
                onClick: (event) =>
                  this.props.history.push("/admin/categories/create"),
              },
            ]}
            columns={[
              { title: "id", field: "ID", hidden: true },
              { title: "İsim", field: "name" },
              { title: "Slug", field: "slug" },
              { title: "Image", field: "image" },
            ]}
            // data={query =>
            //     new Promise((resolve, reject) => {
            //       api.get(`categories?page=${query.page + 1}&limit=${query.pageSize}&sortBy=createdAt:desc`)
            //           .then(res => res.data)
            //           .then(result => {
            //             resolve({
            //               data      : result.results,
            //               page      : result.page - 1,
            //               totalCount: result.totalResults,
            //             })
            //           })
            //
            //     })
            // }

            editable={{
              isEditable: (rowData) => rowData.id,
              isDeletable: (rowData) => rowData.id,
              // onRowDelete: oldData =>
              //     new Promise((resolve, reject) => {
              //
              //       api.delete(`categories/${oldData.id}`)
              //           .then(result => {
              //             resolve();
              //           })
              //     })
            }}
          />

*/}
        </div>
      </div>
    </AdminLayout>
  );
}

export default connect(advertRedux.reducer, {
  ...advertRedux.actions,
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdminDashboard);
