import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetAdvert: "[Admin Advert] Admin advert",
  SetCategory: "[Admin Category] Admin Category detail ",
};

const initialAdvertState = {
  advert: [],
  categoryDetail: {},
};

export const reducer = persistReducer(
  { storage, key: "v713-alicilar-admin", whitelist: ["advertDetail"] },
  (state = initialAdvertState, action) => {
    switch (action.type) {
      case actionTypes.SetAdvert: {
        const { advert } = action.payload;

        return { ...state, advertDetail: advert };
      }
      case actionTypes.SetCategory: {
        const { category } = action.payload;

        return { ...state, categoryDetail: category };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setAdverts: (adverts) => ({
    type: actionTypes.SetAdverts,
    payload: { adverts },
  }),
  setCategory: (category) => ({
    type: actionTypes.SetCategory,
    payload: { category },
  }),
};
