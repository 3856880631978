import React from "react";
import { connect } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as authRedux from "modules/Auth/_redux/authRedux";
import * as initialRedux from "modules/Initial/_redux/initialRedux";
import AdminLayout from "markup/Layout/AdminLayout";
import MaterialTable from "material-table";
import { getUTCDate } from "helpers";
import * as adminEdnpoint from "modules/Admin/_redux/adminEndpoint";

function AdminBoughts(props) {
  return (
    <AdminLayout>
      <div className="page-content bg-white">
        <MaterialTable
          title="Açılan İlanlar"
          options={{
            search: false,
            pageSize: 10,
            //toolbar: false,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} / {count}",
            },
            header: {
              actions: "İşlem",
            },
            body: {
              emptyDataSourceMessage: "",
              filterRow: {
                filterTooltip: "Filtre",
              },
            },
          }}
          columns={[
            { title: "id", field: "ID", hidden: true },
            { title: "İşlem No", field: "id" },
            { title: "Harcanan Bakiye", field: "acoin" },
            {
              title: "İlan",
              field: "advert",
              render: (rowData) => (
                <a target="_blank" href={`/ilan/${rowData.advert.slug}`}>
                  {rowData.advert.title}
                </a>
              ),
            },
            {
              title: "Üye",
              field: "user",
              render: (rowData) => (
                <a
                  target="_blank"
                  href={`/admin/users/${rowData.user.id}`}
                >{`${rowData.user.name} ${rowData.user.surname}`}</a>
              ),
            },
            {
              title: "Tarih",
              field: "createdAt",
              render: (rowData) => <span>{getUTCDate(rowData.createdAt)}</span>,
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              adminEdnpoint
                .getBoughts(query.page, query.pageSize, "desc")
                .then((res) => res.data)
                .then((result) => {
                  resolve({
                    data: result.results,
                    page: result.page - 1,
                    totalCount: result.totalResults,
                  });
                });
            })
          }
          editable={{
            isEditable: (rowData) => rowData.id,
            isDeletable: (rowData) => rowData.id,
          }}
        />
      </div>
    </AdminLayout>
  );
}

export default connect(advertRedux.reducer, {
  ...advertRedux.actions,
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdminBoughts);
