// import i18next from "i18next";
import Login from "./../Login";
import Register from "./../Register";
import AuthRoles from "../../../@core/auth/AuthRoles";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
// import en from './i18n/en';
// import tr from './i18n/tr';
//
// i18next.addResourceBundle('en', 'examplePage', en);
// i18next.addResourceBundle('tr', 'examplePage', tr);

const AuthRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: AuthRoles.onlyGuest,

  routes: [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/register",
      component: Register,
    },
    {
      path: "/forgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/resetPassword",
      component: ResetPassword,
    },
  ],
};

export default AuthRoute;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
