import React, { useState, useEffect } from "react";
import AdminLayout from "../../../../markup/Layout/AdminLayout";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Tab, AppBar, Button } from "@material-ui/core";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import AdminSettingsGeneral from "./AdminSettingsGeneral";
import AdminSettingsNewPassword from "./AdminSettingsNewPassword";
//import AdminCategories from "../AdminCategories";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "50ch",
  },
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  select: {
    width: "100%",
  },
}));

export default function AdminSettings(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    age: 0,
    personName: "",
    names: [
      "Oliver Hansen",
      "Van Henry",
      "April Tucker",
      "Ralph Hubbard",
      "Omar Alexander",
      "Carlos Abbott",
      "Miriam Wagner",
      "Bradley Wilkerson",
      "Virginia Andrews",
      "Kelly Snyder",
    ],
    password: "",
    newPassword: "",
    reNewPassword: "",
    gender: "female",
    description: "",
    size: [],
    selectableStaticData: ["XS", "S", "M", "L", "XL", "XXL"],
    title: "",
    slug: "",
  });

  const handleChangeTags = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <>
      <AdminLayout>
        <div className="page-content bg-white">
          <div className="d-flex flex-row flex-wrap">
            <div className={classes.root}>
              <TabContext value={value}>
                <AppBar position="static" color="default">
                  <TabList
                    onChange={handleChangeTags}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Genel" value="1" />
                    <Tab label="Yeni Şifre" value="2" />
                  </TabList>
                </AppBar>
                <TabPanel value="1">
                  <AdminSettingsGeneral
                    handleChange={handleChange}
                    state={state}
                    classes={classes}
                    theme={theme}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <AdminSettingsNewPassword
                    handleChange={handleChange}
                    state={state}
                  />
                </TabPanel>
              </TabContext>
            </div>
          </div>
          <div style={{ padding: "24px" }}>
            <Button variant="contained" color="secondary" type="submit">
              Kaydet
            </Button>
          </div>
        </div>
      </AdminLayout>
    </>
  );
}
