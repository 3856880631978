import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserProfileLayout from "markup/Layout/UserProfileLayout";
import Profilesidebar from "markup/Element/Profilesidebar";
import * as authCrud from "modules/Auth/_redux/authCrud";
import { toast } from "react-toastify";

function MyPassword(props) {
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });

  function handleChange(e) {
    e.persist();
    setPasswordForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  function changePasswordPost() {
    if (passwordForm.oldPassword == passwordForm.newPassword)
      return toast.error("Yeni şifre ile eski şifre aynı olamaz");
    if (passwordForm.newPassword != passwordForm.newPasswordConfirmation)
      return toast.error("Yeni şifreniz doğrulanamadı");

    authCrud
      .changePassword(passwordForm)
      .then((res) => res.data)
      .then((result) => {
        console.log(result);

        toast.success("Şifreniz değiştirildi");
        setPasswordForm({
          oldPassword: "",
          newPassword: "",
          newPasswordConfirmation: "",
        });
      });
  }

  return (
    <UserProfileLayout>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar {...props} />

                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Şifre Değiştir
                      </h5>
                      <Link
                        to={"/"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Anasayfa
                      </Link>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Eski Şifreniz</label>
                            <input
                              type="password"
                              className="form-control"
                              name="oldPassword"
                              onChange={handleChange}
                              value={passwordForm.oldPassword}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Yeni Şifre </label>
                            <input
                              type="password"
                              className="form-control"
                              name="newPassword"
                              onChange={handleChange}
                              value={passwordForm.newPassword}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Yeni Şifre Doğrulama</label>
                            <input
                              type="password"
                              className="form-control"
                              name="newPasswordConfirmation"
                              onChange={handleChange}
                              value={passwordForm.newPasswordConfirmation}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 m-b10">
                          <button
                            className="site-button"
                            disabled={!passwordForm.newPassword}
                            onClick={changePasswordPost}
                          >
                            Şifreyi Güncelle
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default MyPassword;
//export default connect(null, {...auth.actions})(MyPassword);
