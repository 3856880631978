import React from "react";

const AdminUserOtps = (props) => {
  const { otp } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-sm-2">
            <label className="p-1 text-black admin-user-label">Telefon:</label>
          </div>
          <div className="col-8 col-sm-10">
            <label className="py-1 px-2 admin-user-label label-overflow">
              {otp?.phone}
            </label>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-sm-2">
            <label className="p-1 text-black admin-user-label">
              Telefon Doğrulama Kodu:
            </label>
          </div>
          <div className="col-8 col-sm-10">
            <label className="py-1 px-2 admin-user-label label-overflow">
              {otp?.code}
            </label>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-sm-2">
            <label className="p-1 text-black admin-user-label">
              Telefon Doğrulama Tarihi:
            </label>
          </div>
          <div className="col-8 col-sm-10">
            <label className="py-1 px-2 admin-user-label label-overflow">
              {otp?.createdAt}
            </label>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-sm-2">
            <label className="p-1 text-black admin-user-label">
              Telefon Doğrulama Durumu:
            </label>
          </div>
          <div className="col-8 col-sm-10">
            <label className="py-1 px-2 admin-user-label label-overflow">
              {otp?.verify ? "Doğrulandı" : "Doğrulanmadı"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserOtps;
