import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as authRedux from "modules/Auth/_redux/authRedux";
import * as initialRedux from "modules/Initial/_redux/initialRedux";
import AdminLayout from "markup/Layout/AdminLayout";
import * as adminEndpoint from "modules/Admin/_redux/adminEndpoint";
import { Link, useHistory } from "react-router-dom";
import { generateAdvertUrl } from "helpers";

import TabContext from "@material-ui/lab/TabContext";
import { AppBar, Button, Tab } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
// import TabPanel from "@material-ui/lab/TabPanel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AdminUserGeneral from "./AdminUserDetails/AdminUserGeneral";
import AdminUserOtps from "./AdminUserDetails/AdminUserOtps";
import AdminUserAdverts from "./AdminUserDetails/AdminUserAdverts";
import AdminUserOrders from "./AdminUserDetails/AdminUserOrders";
import AdminUserBoughts from "./AdminUserDetails/AdminUserBoughts";
import AdminUserProvider from "./AdminUserDetails/AdminUserProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "50ch",
  },
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  select: {
    width: "100%",
  },
}));

function AdminUsersDetail(props) {
  const [user, setUser] = useState({});
  const [orders, setOrders] = useState([]);
  const [adverts, setAdverts] = useState([]);
  const [provider, setProvider] = useState([]);
  const [otp, setOtp] = useState({});
  const [boughts, setBoughts] = useState([]);
  let history = useHistory();

  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    let userId = props.match.params.userId;
    window.scrollTo(0, 0);
    adminEndpoint.getUser(userId).then((result) => {
      setUser(result.data.user);
      setOrders(result.data.orders);
      setAdverts(result.data.adverts);
      setOtp(result.data.otps);
      setBoughts(result.data.boughts);
      setProvider(result.data.provider);
      console.log(result.data);
    });
  }, []);

  if (!user.name) {
    return (
      <AdminLayout>
        <div style={{ height: 800 }}>Yükleniyor...</div>
      </AdminLayout>
    );
  }

  const handleChangeTags = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  };

  return (
    <AdminLayout>
      <h3 className="text-black">Üye Detay Bilgileri</h3>
      <div className="page-content bg-white mb-5">
        <div className="d-flex flex-row flex-wrap">
          <div className={classes.root}>
            <TabContext value={value}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Profil" {...a11yProps(0)} />
                  <Tab label="Otp Bilgileri" {...a11yProps(1)} />
                  <Tab label="Oluşturduğu İlanlar" {...a11yProps(2)} />
                  <Tab label="Satın Alınan Bakiye" {...a11yProps(3)} />
                  <Tab label="Açtığı İlanlar" {...a11yProps(4)} />
                  <Tab label="Sosyal Medya Oturum Açmaları" {...a11yProps(5)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <AdminUserGeneral
                  handleChange={handleChange}
                  user={user}
                  classes={classes}
                  theme={theme}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <AdminUserOtps handleChange={handleChange} otp={otp} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <AdminUserAdverts
                  handleChange={handleChange}
                  adverts={adverts}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <AdminUserOrders handleChange={handleChange} orders={orders} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <AdminUserBoughts
                  handleChange={handleChange}
                  boughts={boughts}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <AdminUserProvider
                  handleChange={handleChange}
                  provider={provider}
                />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
      {/*<button className="mb-5" onClick={() => window.open("", "_self").close()}>*/}
      {/*  {" "}*/}
      {/*  Kapat*/}
      {/*</button>*/}
    </AdminLayout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default connect(advertRedux.reducer, {
  ...advertRedux.actions,
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdminUsersDetail);
