import React from "react";
import {
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";

const AdminSettingsGeneral = (props) => {
  const { state, handleChange } = props;
  return (
    <>
      <div className="d-flex mb-4 flexibleInputs">
        <div className="admin-settings-text-input-container">
          <TextField
            name="firstName"
            id="standard-basic"
            className="admin-settings-text-input"
            value={state.firstName}
            label="Ad"
            onChange={handleChange}
          />
        </div>
        <div className="admin-settings-text-input-container">
          <TextField
            name="lastName"
            id="standard-basic"
            className="admin-settings-text-input"
            value={state.lastName}
            label="Soyad"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex mb-4 flexibleInputs">
        <div className="admin-settings-text-input-container">
          <TextField
            name="userName"
            id="standard-basic"
            className="admin-settings-text-input"
            value={state.userName}
            label="Kullanıcı Adı"
            onChange={handleChange}
          />
        </div>
        <div className="admin-settings-text-input-container">
          <TextField
            name="email"
            id="standard-basic"
            className="admin-settings-text-input"
            label="Email"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex mb-4 flexibleInputs">
        <div className="admin-settings-text-input-container">
          <FormControl className="admin-settings-select-container">
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="age"
              // className={classes.select}
              id="demo-simple-select"
              value={state.age}
              onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="admin-settings-text-input-container">
          <FormControl className="admin-settings-select-container">
            <InputLabel id="demo-mutiple-name-label">Ölçüler</InputLabel>
            <Select
              name="size"
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={state.size}
              onChange={handleChange}
              input={<Input />}
            >
              {state.selectableStaticData.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="d-flex mb-4 flexibleInputs">
        <div className="admin-settings-text-input-container">
          <TextField
            name="description"
            label="Açıklama"
            id="standard-basic"
            className="admin-settings-multiple-text-input-container"
            rows={4}
            multiline
            value={state.description}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex mb-4 flexibleInputs">
        <div className="admin-settings-text-input-container">
          <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={state.gender}
              onChange={handleChange}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
              <FormControlLabel
                value="disabled"
                disabled
                control={<Radio />}
                label="(Disabled option)"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default AdminSettingsGeneral;
