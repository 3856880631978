import React from "react";
import "react-pro-sidebar/dist/css/styles.css";
import SearchBox from "./parts/SearchBox";
import AdvertSection from "./parts/AdvertSection";
import LayoutV1 from "markup/Layout/LayoutV1";
import CitySection from "./parts/CitySection";
import { Helmet } from "react-helmet";
import CategoryList from "../Category/CategoryList/CategoryList";
import { Hidden } from "@material-ui/core";
import Market from "../Market/Market";
import { Link } from "react-router-dom";

// let bnr1 = require("images/main-slider/slide2.jpg");
let Alicilar = require("images/dashboard/alicilar_v3.jpg");

function Homepage(props) {
  // const [keyword, setKeyword] = useState("");
  // const [open, setOpen] = useState(false);
  // const [options, setOptions] = React.useState([]);
  // const loading = open && options.length === 0;

  return (
    <LayoutV1>
      <Helmet>
        <title>Alıcılar</title>
        <meta
          name="description"
          content="Almak istediğin ne varsa ilan ver satıcısı seni bulsun !"
        />
      </Helmet>
      {/*TODO:Helmet components will be inside another components.*/}
      <div className="page-content">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-lg-3 mb-3">
              <CategoryList />
            </div>
            <div className="col-xs-12 col-lg-9 search-box-container">
              <SearchBox />
              <Hidden smDown={true}>
                <div>
                  <img
                    src={Alicilar}
                    className="radius-md"
                    style={{
                      width: "100%",
                    }}
                    alt=""
                  />
                </div>
              </Hidden>
            </div>
          </div>
        </div>
        {/*<div*/}
        {/*  className="dez-bnr-inr dez-bnr-inr-md"*/}
        {/*  style={{ backgroundImage: "url(" + bnr1 + ")" }}*/}
        {/*>*/}
        {/*  <div className="container">*/}
        {/*    <div className="dez-bnr-inr-entry align-m">*/}
        {/*      <div className="pb-4">*/}
        {/*        /!*<div>*/}
        {/*							<div className="d-flex justify-content-start">*/}
        {/*								<Link to={"/browse-job"} className="site-button button-sm ml-2">Kategori Seç</Link>*/}
        {/*								<Link to={"/browse-job"} className="site-button button-sm ml-2">Yakın*/}
        {/*									Konumlar</Link>*/}
        {/*							</div>*/}
        {/*						</div>*!/*/}
        {/*        /!*<h2> <span className="text-primary">50,000</span> ve fazlası fırsat sizi bekliyor.</h2>*!/*/}
        {/*        <SearchBox />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/* <CategorySection />*/}

        <AdvertSection />

        <CitySection />
        <Market />
      </div>
    </LayoutV1>
  );
}

export default Homepage;
