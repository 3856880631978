import React, { useEffect, useState } from "react";
import UserProfileLayout from "markup/Layout/UserProfileLayout";
import { makeStyles } from "@material-ui/core/styles";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as advertEndpoint from "modules/Advert/_redux/advertEndpoint";
import * as categoryRedux from "modules/Category/_redux/categoryRedux";
import Stepper from "@material-ui/core/Stepper";
import TextField from "@material-ui/core/TextField";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Profilesidebar from "markup/Element/Profilesidebar";
import "./CreateAdvert.css";
import CreateAdvertFilters from "./parts/CreateAdvertFilters";
import ProvinceDistrictSelect from "./parts/locations/ProvinceDistrictSelect";
import { Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import { formatNumberWithCommas } from "helpers/FormatNumbersWithComma";

const successImage = require("images/success_message.png");

function getSteps() {
  return ["Kategori Seç", "İçerik Belirleme", "Onay"];
}

function CreateAdvert(props) {
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    props.editNewAdvert("allowMessages", true);
    props.editNewAdvert("showPhoneNumber", true);

    window.scrollTo(0, 0);
  }, []);

  const { categories, newAdvert } = useSelector((state) => ({
    categories: state.category.categories,
    newAdvert: state.advert.newAdvert,
  }));

  function displayToastError(message) {
    toast.error(message);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (e) => {
    //e.persist();
    let { name, value, checked } = e.target;
    if (name == "allowMessages" || name == "showPhoneNumber") value = checked;

    if (name == "priceMin" || name == "priceMax") {
      const rawValue = value.replace(/,/g, "");

      if (!isNaN(rawValue) || rawValue === "") {
        if (name == "priceMin")
          props.editNewAdvert(
            "displayPriceMin",
            formatNumberWithCommas(rawValue)
          );
        if (name == "priceMax")
          props.editNewAdvert(
            "displayPriceMax",
            formatNumberWithCommas(rawValue)
          );

        return props.editNewAdvert(name, Number(rawValue));
      }
    }

    props.editNewAdvert(name, value);
  };

  const selectCategory = (category) => {
    props.selectCategory(category);
    handleNext();
  };

  const handleSubmit = () => {
    const { displayPriceMax, displayPriceMin, ...rest } = newAdvert;

    advertEndpoint
      .createAdvert(rest)
      .then((res) => res.data)
      .then(() => {
        window.scrollTo(0, 0);
        setSuccess(true);
        props.clearNewlyCreatedAdvert();
      });
  };

  if (success) {
    return (
      <UserProfileLayout>
        <div className="page-content">
          <div className="d-flex flex-column align-items-center mt-5 mb-5">
            <img src={successImage} alt="" width={200} />
            <h2>İlanınız başarıyla kaydedildi.</h2>
            <div>
              <Link to={"./"} className="site-button button-sm float-right">
                Anasayfa
              </Link>
            </div>
          </div>
        </div>
      </UserProfileLayout>
    );
  }

  return (
    <UserProfileLayout>
      <div className="page-content">
        <div className="content-block container">
          <div className="row pt-5">
            {/*<CreateAdvertSidebar {...props}/>*/}
            <Hidden smDown>
              <Profilesidebar {...props} />
            </Hidden>
            <div className="col-sm-12 col-md-8">
              <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step key={1}>
                    <StepLabel>Kategori seç</StepLabel>
                    <StepContent>
                      <div className="d-flex flex-row flex-wrap">
                        <p>
                          Almak istediğiniz ürünün hangi kategoriye ait olduğunu
                          seçerek ilereyebilirsiniz.
                        </p>
                        {categories.map((category, index) => (
                          <div
                            key={index}
                            onClick={() => selectCategory(category)}
                            className="selectableCategory"
                          >
                            <div
                              style={{
                                borderColor: "black",
                                borderWidth: 10,
                                fontSize: 30,
                                padding: 5,
                              }}
                            >
                              <img
                                src={category.icon}
                                alt={category.title}
                                width={35}
                              />
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              {category.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </StepContent>
                  </Step>
                  <Step key={2}>
                    <StepLabel>Filtre</StepLabel>
                    <StepContent>
                      <p>
                        Detaylı arama sayfasında üst sıralarda gözükmek için
                        ilanınız ile ilgili filtre ayarlarını aşağıdan
                        yapabilirsiniz.
                      </p>
                      <div className="">
                        {newAdvert.selectedCategory && (
                          <CreateAdvertFilters
                            filters={newAdvert.selectedCategory.filters}
                          />
                        )}
                      </div>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Geri
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              Object.keys(newAdvert.filters).length > 0
                                ? handleNext()
                                : displayToastError(
                                    "Lütfen filtreleri doldurun."
                                  )
                            }
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1
                              ? "Bitir"
                              : "İleri"}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step key={3}>
                    <StepLabel>İçerik Girişi</StepLabel>
                    <StepContent>
                      <p>
                        Oluşturmak istediğiniz ilan için başlık ve detay
                        belirtmeniz gerekmektedir, Bu adımda yer alan bilgiler
                        ilan detaylarınızda görüntülenecektir.
                      </p>
                      <div className="d-flex flex-column">
                        <TextField
                          type="text"
                          name="title"
                          variant="outlined"
                          label="İlan Başlığı"
                          onChange={(e) => handleChange(e)}
                          value={newAdvert.title}
                          style={{ width: "100%" }}
                        />
                        <TextField
                          type="text"
                          name="body"
                          variant="outlined"
                          label="İlan Detayları"
                          multiline
                          rows={10}
                          onChange={(e) => handleChange(e)}
                          value={newAdvert.body}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Geri
                          </Button>
                          <Button
                            disabled={
                              newAdvert.title === "" || newAdvert.body === ""
                            }
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                          >
                            İleri
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step key={4}>
                    <StepLabel>Özellikler</StepLabel>
                    <StepContent>
                      <p>
                        İlanınız için minimum ve maximum fiyat aralıklarını
                        belirleyebilirsiniz.
                      </p>
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <TextField
                            type="text"
                            name="priceMin"
                            variant="outlined"
                            label="Min Fiyat"
                            onChange={handleChange}
                            value={newAdvert.displayPriceMin}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <TextField
                            type="text"
                            name="priceMax"
                            variant="outlined"
                            label="Max Fiyat"
                            onChange={handleChange}
                            value={newAdvert.displayPriceMax}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Geri
                          </Button>
                          <Button
                            disabled={
                              newAdvert.priceMin === "" ||
                              newAdvert.priceMax === ""
                            }
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              newAdvert.priceMin > newAdvert.priceMax
                                ? displayToastError(
                                    "Minimum fiyat, maksimum fiyattan yüksek olamaz. Lütfen geçerli bir fiyat aralığı giriniz."
                                  )
                                : handleNext()
                            }
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1
                              ? "Tamam"
                              : "İleri"}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step key={5}>
                    <StepLabel>Adres Bilgileri</StepLabel>
                    <StepContent>
                      <p>
                        İlanınızı ilgilendiren il ve ilçe belirtebilir sadece
                        belirtilen konumlarda ilanınızı listeleyebilirsiniz.
                      </p>
                      <div className="d-flex flex-column">
                        <ProvinceDistrictSelect />
                      </div>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Geri
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              newAdvert.location.province
                                ? handleNext()
                                : displayToastError("Lütfen konumu giriniz.")
                            }
                          >
                            {activeStep === steps.length - 1
                              ? "Bitir"
                              : "Tamamla"}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step key={6}>
                    <StepLabel>Gizlilik</StepLabel>
                    <StepContent>
                      <p>İletişim bilgilerinizin gizliliğini ayarlayın</p>

                      <div className="d-flex flex-column">
                        <div className="custom-control custom-checkbox mb-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="allowMessages"
                            name="allowMessages"
                            onChange={(e) => handleChange(e)}
                            checked={newAdvert.allowMessages}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="allowMessages"
                          >
                            Uygulama içi bana mesaj gönderilmesine izin ver
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox my-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="showPhoneNumber"
                            name="showPhoneNumber"
                            onChange={(e) => handleChange(e)}
                            checked={newAdvert.showPhoneNumber}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="showPhoneNumber"
                          >
                            Telefon numaramı göster
                          </label>
                        </div>
                      </div>

                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Geri
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              !newAdvert.showPhoneNumber &&
                              !newAdvert.allowMessages
                                ? displayToastError(
                                    "Lütfen iki seçenekten en az birini seçiniz."
                                  )
                                : handleNext()
                            }
                          >
                            {activeStep === steps.length - 1
                              ? "Bitir"
                              : "Tamamla"}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step key={7}>
                    <StepLabel>Yayınla</StepLabel>
                    <StepContent>
                      <p>
                        Neredeyse tamam! Yayınla butonu ile ilanınız incelenmek
                        üzere sıraya alınacaktır. Yayınlandığı zaman size
                        e-posta yolu ile ulaşacağız.
                      </p>

                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Geri
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                          >
                            Yayınla
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                </Stepper>

                {/*<button onClick={handleSubmit}>asd</button>*/}

                {activeStep === steps.length && (
                  <Paper
                    square
                    elevation={0}
                    className={classes.resetContainer}
                  >
                    <Typography>Tüm adımlar tamamlandı</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Sayfayı Yenile
                    </Button>
                  </Paper>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  activeButton: {
    backgroundColor: "#11986e",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default connect(null, {
  ...advertRedux.actions,
  ...categoryRedux.actions,
})(CreateAdvert);
