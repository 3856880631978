import Profilesidebar from "markup/Element/Profilesidebar";
import UserProfileLayout from "markup/Layout/UserProfileLayout";
import React from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import * as authCrud from "../_redux/authCrud";
import { generateAdvertUrl, getUTCDate } from "helpers";

function MyBoughts(props) {
  return (
    <UserProfileLayout>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar {...props} />

                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx save-job browse-job table-job-bx clearfix">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Satın Aldıklarım
                      </h5>

                      <Link
                        to={"/"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Anasayfa
                      </Link>
                    </div>

                    <MaterialTable
                      options={{
                        search: false,
                        pageSize: 10,
                        toolbar: false,
                      }}
                      localization={{
                        pagination: {
                          labelDisplayedRows: "{from}-{to} / {count}",
                        },
                        header: {
                          actions: "İşlem",
                        },
                        body: {
                          emptyDataSourceMessage:
                            "Satın aldığınız ilan bulunnmuyor.",
                          filterRow: {
                            filterTooltip: "Filtre",
                          },
                        },
                      }}
                      columns={[
                        { title: "id", field: "ID", hidden: true },
                        {
                          title: "İlan",
                          field: "advert.title",
                          render: (rowData) => (
                            <Link to={generateAdvertUrl(rowData.advert.slug)}>
                              {rowData.advert.title}
                            </Link>
                          ),
                        },
                        { title: "Bakiye", field: "acoin" },
                        {
                          title: "Tarih",
                          field: "createdAt",
                          render: (rowData) => (
                            <span>{getUTCDate(rowData.createdAt)}</span>
                          ),
                        },
                        {
                          title: "İlan Detay",
                          field: "createdAt",
                          render: (rowData) => (
                            <Link to={generateAdvertUrl(rowData.advert.slug)}>
                              <button className="site-button">
                                İlan Detayı
                              </button>
                            </Link>
                          ),
                        },
                      ]}
                      data={(query) =>
                        new Promise((resolve, reject) => {
                          authCrud
                            .myBoughts(query.page, query.pageSize, "desc")
                            .then((res) => res.data.myBought)
                            .then((result) =>
                              resolve({
                                data: result.results,
                                page: result.page - 1,
                                totalCount: result.totalResults,
                              })
                            );
                        })
                      }
                      editable={{
                        isEditable: (rowData) => rowData.id,
                        isDeletable: (rowData) => rowData.id,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default MyBoughts;
