import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect, useSelector } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";

function FLSingleSelect(props) {
  const { filters } = useSelector((state) => ({
    filters: state.advert.newAdvert.filters,
  }));

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.editNewAdvertFilters(name, value);
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth={true} margin="dense">
        <InputLabel id={props.filter.slug}> {props.filter.title}</InputLabel>
        <Select
          name={props.filter.slug}
          labelId={props.filter.slug}
          id={props.filter.slug}
          value={filters[props.filter.slug] ? filters[props.filter.slug] : []}
          onChange={(e) => handleChange(e)}
        >
          {props.filter.values.map((item, index) => (
            <MenuItem value={item} key={index}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
export default connect(null, { ...advertRedux.actions })(FLSingleSelect);
