import { getAdvertDate } from "helpers";
import React from "react";
import { truncateText } from "helpers";

function ChatroomItem({ item, history, currentUserId }) {
  return (
    <div
      className="p-2 mb-2 chatroom-item"
      onClick={() =>
        history.push(`/messages/${item?._id}?advert=${item?.advert?.slug}`)
      }
      style={{
        background:
          item?.lastMessage && item?.lastMessage?.receiver == currentUserId
            ? item?.lastMessage?.seen
              ? "white"
              : "#efefef"
            : "white",
      }}
    >
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">
            <p className="chatroomName">
              {item?.participants?.[0]?.name} {item?.participants?.[0]?.surname}
            </p>

            <p className="chatroomAdvertTitle">{item?.advert?.title}</p>
          </div>

          {item?.lastMessage && item?.lastMessage?.receiver == currentUserId ? (
            item?.lastMessage?.seen ? null : (
              <div className="unreadDot" />
            )
          ) : null}
        </div>
        <span>{truncateText(item?.lastMessage?.body, 50)}</span>
      </div>

      <p className="lastMessageDate">
        {getAdvertDate(item?.lastMessage?.createdAt)}
      </p>
    </div>
  );
}

export default ChatroomItem;
