export const actionTypes = {
  setChatrooms: "[Chatroom] set chatrooms",
  setCurrentChatroom: "[Chatroom] set current chatrooms",
  resetCurrentChatroom: "[Chatroom] reset current chatroom",
  updateMessages: "[Message] update messages",
  deleteMessage: "[Message] delete message",
  resetMessages: "[Message reset messages",
  updateMessagesAsArray: "[Message] update messages as array",
  setMessagesAsSeen: "[Message] set messages as seen",
};

const initialState = {
  chatrooms: [],
  currentChatroom: null,
  messages: [],
  lastMessageFetchDate: null,
  shouldScrollToBottom: true,
  hasUnreadMessages: false,
  checkSeenInformation: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setChatrooms: {
      let hasUnreadMessage = action.payload.chatrooms.some(
        (chatroom) =>
          chatroom?.lastMessage &&
          !chatroom?.lastMessage?.seen &&
          chatroom?.lastMessage.receiver == action.payload.currentUserId
      );

      return {
        ...state,
        chatrooms: action.payload.chatrooms,
        hasUnreadMessage,
      };
    }
    case actionTypes.setCurrentChatroom: {
      return { ...state, currentChatroom: action.payload };
    }
    case actionTypes.resetCurrentChatroom: {
      return { ...state, currentChatroom: null };
    }
    case actionTypes.resetMessages: {
      return {
        ...state,
        messages: [],
        lastMessageFetchDate: null,
        checkSeenInformation: false,
      };
    }
    case actionTypes.updateMessages: {
      return {
        ...state,
        messages: [...state.messages, action.payload],
        shouldScrollToBottom: true,
        checkSeenInformation: false,
      };
    }
    case actionTypes.deleteMessage: {
      return {
        ...state,
        messages: state.messages.filter((item) => item._id != action.payload),
        checkSeenInformation: false,
      };
    }
    case actionTypes.updateMessagesAsArray: {
      let isInitialFetch = state.messages.length == 0; // If it's initial fetch then scroll to bottom

      const newMessages = [
        ...state.messages,
        ...action.payload.messages.filter((newMessage) => {
          return !state.messages.some(
            (message) => message._id == newMessage._id
          );
        }),
      ];

      let shouldScroll = false;
      if (newMessages[newMessages.length - 1] && !isInitialFetch)
        shouldScroll =
          newMessages[newMessages.length - 1].receiver.id ==
            action.payload.currentUserId &&
          !newMessages[newMessages.length - 1].seen; // If receiver's id at the latest message matches the current user Id and message is unread, this means current user should see the newly sent message

      return {
        ...state,
        //messages: newMessages,
        messages: action.payload.messages,
        lastMessageFetchDate: action.payload.date,
        shouldScrollToBottom: isInitialFetch ? true : shouldScroll,
        checkSeenInformation: true,
      };
    }
    case actionTypes.setMessagesAsSeen: {
      return {
        ...state,
        messages: state.messages.map((message) => {
          if (action.payload.includes(message._id)) {
            return { ...message, seen: true };
          } else {
            return message;
          }
        }),
        hasUnreadMessage: false,
        checkSeenInformation: false,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  setChatrooms: (data) => ({
    type: actionTypes.setChatrooms,
    payload: data,
  }),
  setCurrentChatroom: (data) => ({
    type: actionTypes.setCurrentChatroom,
    payload: data,
  }),
  updateMessages: (data) => ({
    type: actionTypes.updateMessages,
    payload: data,
  }),
  deleteMessage: (data) => ({ type: actionTypes.deleteMessage, payload: data }),
  updateMessagesAsArray: (data) => ({
    type: actionTypes.updateMessagesAsArray,
    payload: {
      messages: data.messages,
      date: new Date().toISOString(),
      currentUserId: data.currentUserId,
    },
  }),
  setMessagesAsSeen: (data) => ({
    type: actionTypes.setMessagesAsSeen,
    payload: data,
  }),
  resetMessages: () => ({
    type: actionTypes.resetMessages,
  }),
  resetCurrentChatroom: () => ({
    type: actionTypes.resetCurrentChatroom,
  }),
};
