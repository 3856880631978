import React from "react";
import { Link } from "react-router-dom";
import Profilesidebar from "../../../markup/Element/Profilesidebar";
import UserProfileLayout from "../../../markup/Layout/UserProfileLayout";
import * as authCrud from "../../Auth/_redux/authCrud";
import { getUTCDate } from "../../../helpers";
import MaterialTable from "material-table";

function MyCredits(props) {
  return (
    <UserProfileLayout>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar {...props} />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx save-job browse-job table-job-bx clearfix">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Kredi Yükleme Geçmişi
                      </h5>
                      <Link
                        to={"./"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Anasayfa
                      </Link>
                    </div>

                    <MaterialTable
                      options={{
                        search: false,
                        pageSize: 10,
                        toolbar: false,
                      }}
                      localization={{
                        pagination: {
                          labelDisplayedRows: "{from}-{to} / {count}",
                        },
                        header: {
                          actions: "İşlem",
                        },
                        body: {
                          emptyDataSourceMessage:
                            "Kredi satın alma işleminiz bulunmuyor.",
                          filterRow: {
                            filterTooltip: "Filtre",
                          },
                        },
                      }}
                      columns={[
                        { title: "id", field: "ID", hidden: true },
                        { title: "Bakiye", field: "acoin" },
                        {
                          title: "Tutar",
                          field: "iyzicoResponse.price",
                          render: (rowData) => {
                            return (
                              <span>
                                {rowData?.campaignResponse
                                  ? "Hediye Çeki"
                                  : rowData?.iyzicoResponse
                                  ? `${rowData.iyzicoResponse?.price} TL`
                                  : `${rowData.items[0].price} TL`}
                              </span>
                            );
                          },
                        },
                        {
                          title: "Satın Alma Tarihi",
                          field: "createdAt",
                          render: (rowData) => (
                            <span>{getUTCDate(rowData.createdAt)}</span>
                          ),
                        },
                      ]}
                      data={(query) =>
                        new Promise((resolve, reject) => {
                          authCrud
                            .myCredits(query.page, query.pageSize, "desc")
                            .then((res) => res.data.credits)
                            .then((result) => {
                              resolve({
                                data: result.results,
                                page: result.page - 1,
                                totalCount: result.totalResults,
                              });
                            });
                        })
                      }
                      editable={{
                        isEditable: (rowData) => rowData.id,
                        isDeletable: (rowData) => rowData.id,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default MyCredits;
