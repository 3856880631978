import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  GetInitial: "[Initial] Get Initial Data",
  SetProducts: "[Initial] Set Products",
  SetPages: "[Initial] Set Pages",
  SetUserCity: "[Initial] Set User City",

  ToggleSidebarModal: "[View] Toggle Sidebar Modal",

  // Cities
  SetSelectedCities: "[Initial] Set Selected Cities",

  // PACE Kontrol
  OpenPace: "[Pace] Open Pace",
  ClosePace: "[Pace] Close Pace",
};

const initialInitialState = {
  initial: {
    title: "",
    company: "",
  },
  modal: {
    sidebar: false,
  },
  products: [],
  pages: [],
  selectedCities: [],
  userCity: {},
  paceLoading: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v713-alicilar-initial",
    whitelist: ["initial", "products", "userCity"],
  },
  (state = initialInitialState, action) => {
    switch (action.type) {
      case actionTypes.GetInitial: {
        const { categories } = action.payload;
        return { ...state, categories };
      }

      case actionTypes.SetProducts: {
        const { products } = action.payload;
        return { ...state, products };
      }

      case actionTypes.SetSelectedCities: {
        const { cities } = action.payload;
        return { ...state, selectedCities: cities };
      }

      case actionTypes.SetPages: {
        const { pages } = action.payload;
        return { ...state, pages };
      }

      case actionTypes.SetUserCity: {
        const { userCity } = action.payload;
        return { ...state, userCity };
      }

      case actionTypes.ToggleSidebarModal: {
        return {
          ...state,
          modal: {
            sidebar: !state.modal.sidebar,
          },
        };
      }

      case actionTypes.OpenPace: {
        return { ...state, paceLoading: true };
      }

      case actionTypes.ClosePace: {
        return { ...state, paceLoading: false };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  toggleSidebarModal: () => ({
    type: actionTypes.ToggleSidebarModal,
  }),

  setProducts: (products) => ({
    type: actionTypes.SetProducts,
    payload: { products },
  }),
  setPages: (pages) => ({
    type: actionTypes.SetPages,
    payload: { pages },
  }),
  setSelectedCities: (cities) => ({
    type: actionTypes.SetSelectedCities,
    payload: { cities },
  }),
  setUserCity: (userCity) => ({
    type: actionTypes.SetUserCity,
    payload: { userCity },
  }),
  openPace: () => ({ type: actionTypes.OpenPace }),
  closePace: () => ({ type: actionTypes.ClosePace }),
};
