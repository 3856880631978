import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as categoryRedux from "../_redux/categoryRedux";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import ComputerOutlinedIcon from "@material-ui/icons/ComputerOutlined";
import { generateCategoryDetail } from "helpers";

const CategoryList = () => {
  const { categories } = useSelector(
    (state) => ({
      categories: state.category.categories,
    }),
    shallowEqual
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid spacing={3}>
        <Grid item sx={12}>
          <Paper className={classes.categoryHeader}>Kategoriler </Paper>
        </Grid>
        <Grid className={classes.categoryListContainer}>
          {categories.map((category) => (
            <div key={category._id}>
              <Link to={generateCategoryDetail(category.slug)}>
                <Grid item xs={12}>
                  <Paper
                    className={classes.categoryItemsContainer}
                    style={{ boxShadow: "none" }}
                  >
                    <Box
                      className={classes.categoryBox}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Grid
                        className={classes.categoryItems}
                        style={{
                          backgroundColor: "#f2fffd",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          src={category.icon}
                          alt={category.title}
                          width={30}
                          style={{ borderRadius: "50%" }}
                        />
                      </Grid>
                      <Box
                        className={classes.categoryItemsContent}
                        p={1}
                        textOverflow="ellipsis"
                      >
                        {category.name}
                      </Box>
                    </Box>
                    <Hidden smDown={true}>
                      <IconButton
                        aria-label="cart"
                        style={{ position: "relative", bottom: "0.3em" }}
                      >
                        <StyledBadge
                          badgeContent={category.rate}
                          max={9999}
                          color="secondary"
                        />
                      </IconButton>
                    </Hidden>
                  </Paper>
                </Grid>
              </Link>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "0 0 20px 0 rgb(0 0 0 / 20%)",
    borderRadius: "8px",
    background: "#fff",
    height: "100%",
  },
  categoryHeader: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "black",
    fontWeight: "600",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    fontSize: "1.1rem",
    letterSpacing: "0.5px",
  },
  categoryItemsContainer: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    textAlign: "left",
    color: theme.palette.text.secondary,
    borderRadius: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  categoryItems: {
    borderRadius: "50%",
    padding: "8px",
    display: "flex",
    alignItems: "center",
  },
  categoryListContainer: {
    height: "390px",
    overflowY: "scroll",
    padding: "0 5px 0 0",
    background: "#fff",
    // borderRadius: "8px",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      overflowX: "scroll",
      height: "auto",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: "340px",
    },
  },
  categoryItemsContent: {
    fontWeight: "400",
    color: "#555555",
    fontSize: "1.1rem",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "14px",
    },
  },
  categoryBox: {
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
  },
  icon: {
    color: "#fff",
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

export default connect(null, {
  ...advertRedux.actions,
  ...categoryRedux.actions,
})(CategoryList);
