import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserProfileLayout from "markup/Layout/UserProfileLayout";
import Profilesidebar from "markup/Element/Profilesidebar";
import MaterialTable from "material-table";
import { generateAdvertUrl, getUTCDate } from "helpers";
import * as authCrud from "modules/Auth/_redux/authCrud";
import { Checkbox } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";

function MyAdverts(props) {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchTableData();
  }, []);

  const fetchTableData = () => {
    authCrud
      .myAdverts(0, 10, "desc", true)
      .then((res) => res.data.myAdverts)
      .then((result) => {
        setTableData(result.results);
      })
      .catch((error) => {
        toast.error("İlanları yüklerken bir sorun oluştu");
      });
  };

  /*state = {
        // initial state
        show: false,
    }

    handleClose = () => {
        this.setState({ show: false });
    };
    handleShow = () => {
        this.setState({ show: true });
    };*/

  const handleAdvertStatusChange = (data, advertId) => {
    axios
      .post(`/v1/adverts/update-status/${advertId}`, { active: data })
      .then(({ data }) => {
        setTableData((prevData) =>
          prevData.map((advert) => (advert.id === advertId ? data : advert))
        );

        toast.success("İlanınız başarıyla güncellendi");
      })
      .catch((err) => {
        toast.error("İlan güncellenirken bir sorun oluştu");
      });
  };

  return (
    <UserProfileLayout>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar {...props} />

                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx save-job browse-job table-job-bx clearfix">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        İlanlarım
                      </h5>
                      <Link
                        to={"/"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Anasayfa
                      </Link>
                    </div>

                    <MaterialTable
                      options={{
                        search: false,
                        pageSize: 10,
                        toolbar: false,
                      }}
                      localization={{
                        pagination: {
                          labelDisplayedRows: "{from}-{to} / {count}",
                        },
                        header: {
                          actions: "İşlem",
                        },
                        body: {
                          emptyDataSourceMessage:
                            "Yayında ilanınız bulunmuyor.",
                          filterRow: {
                            filterTooltip: "Filtre",
                          },
                        },
                      }}
                      columns={[
                        { title: "id", field: "ID", hidden: true },
                        {
                          title: "İlan",
                          field: "advert.title",
                          render: (rowData) => (
                            <Link to={generateAdvertUrl(rowData.slug)}>
                              {rowData.title}
                            </Link>
                          ),
                        },
                        { title: "Bakiye", field: "acoin" },
                        {
                          title: "İlan Detay",
                          field: "createdAt",
                          render: (rowData) => (
                            <Link to={generateAdvertUrl(rowData.slug)}>
                              <button className="site-button">
                                İlan Detayı
                              </button>
                            </Link>
                          ),
                        },
                        {
                          title: "Aktif/Pasif",
                          field: "active",
                          render: (rowData) => (
                            <Checkbox
                              checked={rowData.active}
                              onChange={() =>
                                handleAdvertStatusChange(
                                  !rowData.active,
                                  rowData.id
                                )
                              }
                              color="primary"
                            />
                          ),
                        },
                      ]}
                      data={tableData}
                      editable={{
                        isEditable: (rowData) => rowData.id,
                        isDeletable: (rowData) => rowData.id,
                      }}
                    />

                    {/*<div className="pagination-bx float-right">*/}
                    {/*  <ul className="pagination">*/}
                    {/*    <li className="previous">*/}
                    {/*      <Link to={""}>*/}
                    {/*        <i className="ti-arrow-left"></i> Prev*/}
                    {/*      </Link>*/}
                    {/*    </li>*/}
                    {/*    <li className="active">*/}
                    {/*      <Link to={""}>1</Link>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*      <Link to={""}>2</Link>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*      <Link to={""}>3</Link>*/}
                    {/*    </li>*/}
                    {/*    <li className="next">*/}
                    {/*      <Link to={""}>*/}
                    {/*        Next <i className="ti-arrow-right"></i>*/}
                    {/*      </Link>*/}
                    {/*    </li>*/}
                    {/*  </ul>*/}
                    {/*</div>*/}
                  </div>

                  {/*<Modal show={this.state.show} onHide={this.handleClose}
										   className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1"
										   role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
										<div className="modal-dialog  my-0" role="document">
											<div className="modal-content">
												<div className="modal-header">
													<div className="logo-img">
														<img alt="" src={require("./../../../images/logo/icon2.png")}/>
													</div>
													<h5 className="modal-title">Company Name</h5>
													<button type="button" className="close" data-dismiss="modal"
															aria-label="Close" onClick={this.handleClose}>
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
												<div className="modal-body">
													<ul>
														<li><strong>Job Title :</strong><p> Web Developer – PHP, HTML,
															CSS </p></li>
														<li><strong>Experience :</strong><p>5 Year 3 Months</p></li>
														<li><strong>Deseription :</strong>
															<p>Lorem Ipsum is simply dummy text of the printing and
																typesetting industry has been the industry's standard dummy
																text ever since.</p></li>
													</ul>
												</div>
												<div className="modal-footer">
													<button type="button" className="btn btn-secondary" data-dismiss="modal"
															onClick={this.handleClose}>Close
													</button>
												</div>
											</div>
										</div>
									</Modal>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default MyAdverts;
