import { Link } from "react-router-dom";
import React from "react";
import "css/advert-card-vbug/advert-card-vbug.css";
import {
  generateAdvertUrl,
  generateCategoryDetail,
  getAdvertDate,
  turkishLowerCase,
} from "helpers";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import { convertPrice } from "../../../helpers/PriceHelper";

function AdvertCardListView({ advert }) {
  return (
    <div className="col-12">
      <div
        className="post-bx mb-4"
        style={{
          border: "none",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <div className="d-flex" style={{ position: "relative" }}>
          <div className="advert-card-img-container-list-view shadow">
            <Link to={generateAdvertUrl(advert.slug, advert.id)}>
              <img
                alt=""
                src={advert.images}
                className="advert-card-img-list-view  "
              />
              <div className="d-flex align-items-center vbug-view">
                {advert.viewCount}
                <div className="ml-2">
                  <i className="fa fa-eye" style={{ fontSize: "20px" }} />
                </div>
              </div>
            </Link>
          </div>
          <div
            className="d-flex flex-column p-3"
            style={{ position: "relative" }}
          >
            <div className="vbug-category">
              <Link
                to={generateCategoryDetail(advert.category?.slug)}
                className="category-link"
              >
                {advert.category?.name}
              </Link>
            </div>
            <Link
              to={generateAdvertUrl(advert.slug, advert.id)}
              className="vbug-link"
            >
              {advert.title}
            </Link>
            <div className="d-flex align-items-center post-price pt-2 pb-2">
              <div className="d-flex align-items-center">
                <PaymentOutlinedIcon className="advert-card-icon" />
              </div>
              <div className="pl-2" style={{ fontWeight: "500" }}>
                {convertPrice(advert.priceMin)} -{" "}
                {convertPrice(advert.priceMax)} TL
              </div>
            </div>
            <div className="d-flex align-items-center post-price pt-2 pb-2">
              <i className="fa fa-clock-o advert-card-icon" />
              <span
                className="ml-1 pl-2 "
                style={{ fontSize: "12px", fontWeight: "500" }}
              >
                {getAdvertDate(advert.createdAt)}
              </span>
            </div>

            <div className="d-flex align-items-center post-price pt-2 pb-2">
              <i className="fa fa-location-arrow advert-card-icon" />
              <span
                className="ml-1 pl-2"
                style={{ fontSize: "12px", fontWeight: "500" }}
              >
                {turkishLowerCase(advert.location?.province?.name)}
                {` ${advert.location?.district?.name ? "/" : ""}`}
                {turkishLowerCase(advert.location?.district?.name)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvertCardListView;
