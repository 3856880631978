import React, { useCallback, useState } from "react";
import LayoutV1 from "markup/Layout/LayoutV1";
import { isValidTurkishPhoneNumber } from "helpers/PhoneFormats";
import { toast } from "react-toastify";
import {
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from "@material-ui/core";
import PhoneField from "modules/Auth/parts/PhoneField";
import { connect, useSelector } from "react-redux";
import * as advertRedux from "modules/Advert/_redux/advertRedux";
import * as categoryRedux from "modules/Category/_redux/categoryRedux";
import CreateAdvertFilters from "modules/Advert/views/parts/CreateAdvertFilters";
import Button from "@material-ui/core/Button";
import { formatNumberWithCommas } from "helpers/FormatNumbersWithComma";
import ProvinceDistrictSelect from "modules/Advert/views/parts/locations/ProvinceDistrictSelect";
import * as advertEndpoint from "modules/Advert/_redux/advertEndpoint";
import { useDropzone } from "react-dropzone";

const formData = new FormData();
const formDataFields = [
  "image",
  "body",
  "category",
  "filters",
  "location",
  "priceMax",
  "priceMin",
  "selectedCategory",
  "title",
  "user",
];

function getSteps() {
  return ["Kategori Seç", "İçerik Belirleme", "Onay"];
}

function FastAdvertCreationPage(props) {
  const classes = useStyles();
  const steps = getSteps();

  const [user, setUser] = useState({
    phoneNumber: "",
    fullname: "",
    email: "",
  });

  const { categories, newAdvert } = useSelector((state) => ({
    categories: state.category.categories,
    newAdvert: state.advert.newAdvert,
  }));

  const [selectedFile, setSelectedFile] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    let ins = acceptedFiles.length;
    for (let x = 0; x < ins; x++) {
      setSelectedFile(acceptedFiles[x].path);
      formData.append(`image`, acceptedFiles[x]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const submitForm = (e) => {
    const { phoneNumber, fullname, email } = user;

    if (!fullname || !email || !phoneNumber)
      return toast.error("Lütfen tüm alanları doldurun");

    if (!isValidTurkishPhoneNumber(phoneNumber))
      return toast.error("Lütfen geçerli bir telefon numarası giriniz");

    const { displayPriceMax, displayPriceMin, ...rest } = newAdvert;

    formData.append("body", rest.body);
    formData.append("category", rest.category);
    formData.append("filters", JSON.stringify(rest.filters));
    formData.append("location", JSON.stringify(rest.location));
    formData.append("priceMax", rest.priceMax);
    formData.append("priceMin", rest.priceMin);
    formData.append("selectedCategory", JSON.stringify(rest.selectedCategory));
    formData.append("title", rest.title);
    formData.append("user", JSON.stringify(user));

    advertEndpoint
      .createAdvertWithoutLogin(formData)
      .then((res) => res.data)
      .then(() => {
        window.scrollTo(0, 0);
        toast.success("İlan Oluşturuldu!");
        props.clearNewlyCreatedAdvert();
        handleReset();
        setUser({ phoneNumber: "", fullname: "", email: "" });
        for (let key of formDataFields) {
          formData.delete(key);
        }
      });
  };

  const handleUserFormData = (name, value) => {
    setUser((prevData) => ({ ...prevData, [name]: value }));
  };

  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const selectCategory = (category) => {
    props.selectCategory(category);
    handleNext();
  };

  function displayToastError(message) {
    toast.error(message);
  }

  const handleChange = (e) => {
    //e.persist();
    let { name, value, checked } = e.target;
    if (name == "allowMessages" || name == "showPhoneNumber") value = checked;

    if (name == "priceMin" || name == "priceMax") {
      const rawValue = value.replace(/,/g, "");

      if (!isNaN(rawValue) || rawValue === "") {
        if (name == "priceMin")
          props.editNewAdvert(
            "displayPriceMin",
            formatNumberWithCommas(rawValue)
          );
        if (name == "priceMax")
          props.editNewAdvert(
            "displayPriceMax",
            formatNumberWithCommas(rawValue)
          );

        return props.editNewAdvert(name, Number(rawValue));
      }
    }

    props.editNewAdvert(name, value);
  };

  return (
    <LayoutV1>
      <div className="container py-3">
        <h3 className="text-center">İlan Oluştur</h3>

        <div className="row">
          <div className="col-md-4 mb-3">
            <TextField
              type="text"
              name="fullname"
              variant="outlined"
              label="İsim"
              onChange={(e) => handleUserFormData("fullname", e.target.value)}
              value={user.fullname}
              style={{ width: "100%", background: "white" }}
            />
          </div>

          <div className="col-md-4 mb-3">
            <TextField
              type="email"
              name="email"
              variant="outlined"
              label="E-Posta"
              onChange={(e) => handleUserFormData("email", e.target.value)}
              value={user.email}
              style={{ width: "100%", background: "white" }}
            />
          </div>

          <div className="col-md-4 mb-4">
            <PhoneField
              value={user.phoneNumber}
              onChange={(e) =>
                handleUserFormData("phoneNumber", e.target.value)
              }
              style={{
                minHeight: 50,
                height: "100%",
                borderRadius: 5,
                borderColor: "#ccc",
              }}
            />
          </div>
        </div>

        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step key={1}>
              <StepLabel>Kategori seç</StepLabel>
              <StepContent>
                <p>
                  Almak istediğiniz ürünün hangi kategoriye ait olduğunu seçerek
                  ilereyebilirsiniz.
                </p>
                <div className="d-flex flex-row flex-wrap">
                  {categories.map((category, index) => (
                    <div
                      key={index}
                      onClick={() => selectCategory(category)}
                      className="selectableCategory"
                    >
                      <div
                        style={{
                          borderColor: "black",
                          borderWidth: 10,
                          fontSize: 30,
                          padding: 5,
                        }}
                      >
                        <img
                          src={category.icon}
                          alt={category.title}
                          width={35}
                        />
                      </div>
                      <div style={{ fontWeight: "bold" }}>{category.name}</div>
                    </div>
                  ))}
                </div>
              </StepContent>
            </Step>

            <Step key={2}>
              <StepLabel>Filtre</StepLabel>
              <StepContent>
                <p>
                  Detaylı arama sayfasında üst sıralarda gözükmek için ilanınız
                  ile ilgili filtre ayarlarını aşağıdan yapabilirsiniz.
                </p>
                <div className="">
                  {newAdvert.selectedCategory && (
                    <CreateAdvertFilters
                      filters={newAdvert.selectedCategory.filters}
                    />
                  )}
                </div>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Geri
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        Object.keys(newAdvert.filters).length > 0
                          ? handleNext()
                          : displayToastError("Lütfen filtreleri doldurun.")
                      }
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Bitir" : "İleri"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>

            <Step key={3}>
              <StepLabel>İçerik Girişi</StepLabel>
              <StepContent>
                <p>
                  Oluşturmak istediğiniz ilan için başlık ve detay belirtmeniz
                  gerekmektedir, Bu adımda yer alan bilgiler ilan detaylarınızda
                  görüntülenecektir.
                </p>
                <div className="d-flex flex-column">
                  <TextField
                    type="text"
                    name="title"
                    variant="outlined"
                    label="İlan Başlığı"
                    onChange={(e) => handleChange(e)}
                    value={newAdvert.title}
                    style={{ width: "100%" }}
                  />
                  <TextField
                    type="text"
                    name="body"
                    variant="outlined"
                    label="İlan Detayları"
                    multiline
                    rows={10}
                    onChange={(e) => handleChange(e)}
                    value={newAdvert.body}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Geri
                    </Button>
                    <Button
                      disabled={newAdvert.title === "" || newAdvert.body === ""}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      İleri
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>

            <Step key={4}>
              <StepLabel>Özellikler</StepLabel>
              <StepContent>
                <p>
                  İlanınız için minimum ve maximum fiyat aralıklarını
                  belirleyebilirsiniz.
                </p>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <TextField
                      type="text"
                      name="priceMin"
                      variant="outlined"
                      label="Min Fiyat"
                      onChange={handleChange}
                      value={newAdvert.displayPriceMin}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <TextField
                      type="text"
                      name="priceMax"
                      variant="outlined"
                      label="Max Fiyat"
                      onChange={handleChange}
                      value={newAdvert.displayPriceMax}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Geri
                    </Button>
                    <Button
                      disabled={
                        newAdvert.priceMin === "" || newAdvert.priceMax === ""
                      }
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        newAdvert.priceMin > newAdvert.priceMax
                          ? displayToastError(
                              "Minimum fiyat, maksimum fiyattan yüksek olamaz. Lütfen geçerli bir fiyat aralığı giriniz."
                            )
                          : handleNext()
                      }
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Tamam" : "İleri"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>

            <Step key={5}>
              <StepLabel>Adres Bilgileri</StepLabel>
              <StepContent>
                <p>
                  İlanınızı ilgilendiren il ve ilçe belirtebilir sadece
                  belirtilen konumlarda ilanınızı listeleyebilirsiniz.
                </p>

                <div>
                  <ProvinceDistrictSelect />
                </div>

                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Geri
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        newAdvert.location.province
                          ? handleNext()
                          : displayToastError("Lütfen konumu giriniz.")
                      }
                    >
                      {activeStep === steps.length - 1 ? "Bitir" : "Tamamla"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>

            <Step key={6}>
              <StepLabel>Fotoğraf</StepLabel>

              <StepContent>
                <div {...getRootProps()}>
                  <input {...getInputProps()} multiple={false} />
                  {isDragActive ? (
                    <p
                      style={{
                        height: 60,
                        width: 300,
                        backgroundColor: "#eeffeb",
                        borderRadius: 10,
                        padding: 10,
                        color: "black",
                      }}
                    >
                      Şimdi bırak
                    </p>
                  ) : (
                    <p
                      style={{
                        height: 60,
                        width: 300,
                        backgroundColor: "#eeffeb",
                        borderRadius: 10,
                        padding: 10,
                        color: "black",
                      }}
                    >
                      {selectedFile ? selectedFile : "Dosya Yükle"}
                    </p>
                  )}
                </div>

                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Geri
                    </Button>
                    <Button
                      disabled={!selectedFile}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Tamam" : "İleri"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>

            <Step key={7}>
              <StepLabel>Yayınla</StepLabel>
              <StepContent>
                <p>
                  Neredeyse tamam! Yayınla butonu ile ilanınız incelenmek üzere
                  sıraya alınacaktır. Yayınlandığı zaman size e-posta yolu ile
                  ulaşacağız.
                </p>

                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Geri
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      Yayınla
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          </Stepper>
        </div>
      </div>
    </LayoutV1>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  activeButton: {
    backgroundColor: "#11986e",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default connect(null, {
  ...advertRedux.actions,
  ...categoryRedux.actions,
})(FastAdvertCreationPage);
