import FilterCategory from "../FilterCategory";
import CityCategory from "../CityCategory";

const CategoryPublicRoute = {
  routes: [
    {
      path: "/kategori/:categorySlug",
      component: FilterCategory,
    },
    {
      path: "/sehir/:cityNo",
      component: CityCategory,
    },
  ],
};

export default CategoryPublicRoute;
