import AuthRoles from "../../../@core/auth/AuthRoles";
import MyProfile from "../views/MyProfile";
import MyCredits from "../views/MyCredits";
import MyAdverts from "../views/MyAdverts";
import MyPassword from "../views/MyPassword";
import TimeOutAdverts from "../../Advert/views/TimeOutAdverts";
import MyCampaign from "../views/MyCampaign";
import MyBoughts from "../views/MyBoughts";
import Chatrooms from "../views/Chatrooms";
import Messaging from "../views/Messaging";

const AuthRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: AuthRoles.user,

  routes: [
    {
      path: "/messages/:id",
      component: Messaging,
    },
    {
      path: "/messages",
      component: Chatrooms,
    },
    {
      path: "/my-profile",
      component: MyProfile,
    },
    {
      path: "/my-credits",
      component: MyCredits,
    },
    {
      path: "/my-adverts",
      component: MyAdverts,
    },
    {
      path: "/my-boughts",
      component: MyBoughts,
    },
    {
      path: "/my-campaign",
      component: MyCampaign,
    },
    {
      path: "/my-timedout-adverts",
      component: TimeOutAdverts,
    },
    {
      path: "/my-password",
      component: MyPassword,
    },
  ],
};

export default AuthRoute;
