import React from "react";
import { ToastContainer } from "react-toastify";
import CustomProSidebar from "./markup/Element/CustomProSidebar";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "react-router-dom";
import Auth from "@core/auth/Auth";
import AppContext from "./AppContext";
import routes from "./config/routesConfig";
import store, { persistor } from "./redux/store";
import history from "./@history";
import AuthControl from "@core/auth/AuthControl";
import DynamicRoutes from "./DynamicRoutes";

import { QueryParamProvider } from "use-query-params";
import { Route } from "react-router-dom";

import "./css/plugins.css";
import "./css/style.css";
import "./css/template.css";
import "./css/skin/skin-1.css";
import "./css/custom.css";
import "./css/custom.scss";
import "./plugins/slick/slick.min.css";
import "./plugins/slick/slick-theme.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-pro-sidebar/dist/css/styles.css";

const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<div>yükleniyor</div>}>
          <Auth>
            <Router history={history}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <AuthControl>
                  {/*Routes*/}
                  <DynamicRoutes />

                  {/*Custom Components*/}
                  <ToastContainer
                    position="bottom-right"
                    hideProgressBar={true}
                  />

                  {/*Vertical sidebar*/}
                  <CustomProSidebar history={history} />
                </AuthControl>
              </QueryParamProvider>
            </Router>
          </Auth>
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  );
};
export default App;
