import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import { Modal } from "react-bootstrap";
import "react-pro-sidebar/dist/css/styles.css";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as initial from "modules/Initial/_redux/initialRedux";
import profileImage from "images/profileImage.jpg";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link } from "react-router-dom";
import * as auth from "modules/Auth/_redux/authRedux";

function CustomProSidebar(props) {
  const { user, sidebar } = useSelector(
    (state) => ({
      user: state.auth.user,
      sidebar: state.initial.modal.sidebar,
    }),
    shallowEqual
  );

  const hasUnreadMessage = useSelector(
    (state) => state.messages.hasUnreadMessage
  );

  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (avatar === null) setAvatar(profileImage);
  }, [avatar]);

  function logout(e) {
    e.preventDefault();
    props.toggleSidebarModal(true);
    props.logout();
    props.history.push(`/`);
  }

  return (
    <Modal
      keyboard={true}
      id="alicilarSidebarModal"
      onHide={() => props.toggleSidebarModal()}
      className="modal left fade modal-sidebar"
      show={sidebar}
    >
      <div style={{ height: "100vh", backgroundColor: "black" }}>
        <ProSidebar
          collapsed={false}
          toggled={false}
          //image={bnr1}
          //breakPoint="md"
          //onToggle={handleToggleSidebar}
        >
          <SidebarHeader>
            <div
              style={{
                padding: "24px",
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: 14,
                letterSpacing: "1px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Menü
            </div>
          </SidebarHeader>
          {user.name && (
            <SidebarHeader>
              <div className="d-flex justify-content-start pl-3 pt-4 pb-4">
                {/*<div className="avatar">
                  <input
                    type="file"
                    id="addAvatarImg"
                    onChange={(e) =>
                      setAvatar(window.URL.createObjectURL(e.target.files[0]))
                    }
                  />
                  <div
                    className={
                      avatar === (null || profileImage)
                        ? "edit addEdit"
                        : "edit"
                    }
                  >
                    <EditOutlinedIcon />
                  </div>
                  <div className="avatar-img">
                    <img id="sliderImage" src={avatar} alt="" />
                  </div>
                </div>*/}

                <div className="pl-3 mt-2">
                  <p
                    className="slider-header-text"
                    style={{ fontSize: "14px" }}
                  >
                    Hoşgeldin
                  </p>
                  <p className="slider-header-text">{`${user.name} ${user.surname}`}</p>
                </div>
              </div>
            </SidebarHeader>
          )}
          <SidebarContent>
            {user.name && (
              <Menu iconShape="circle">
                <MenuItem icon={<HomeOutlinedIcon />}>
                  <Link
                    to={"/"}
                    // className={props.match.path === '/my-profile' && 'active'}
                    onClick={() => props.toggleSidebarModal(true)}
                  >
                    Anasayfa
                  </Link>
                </MenuItem>
                <MenuItem icon={<PersonOutlineOutlinedIcon />}>
                  <Link
                    to={"/my-profile"}
                    // className={props.match.path === '/my-profile' && 'active'}
                    onClick={() => props.toggleSidebarModal(true)}
                  >
                    Profil
                  </Link>
                </MenuItem>
                <MenuItem icon={<MailOutlineOutlinedIcon />}>
                  <Link
                    to={"/messages"}
                    //className={props.match.path === "/messages" && "active"}
                    onClick={() => props.toggleSidebarModal(true)}
                    className="d-flex align-items-center"
                  >
                    Mesajlar
                    {hasUnreadMessage ? (
                      <div className="unreadDot">!</div>
                    ) : null}
                  </Link>
                </MenuItem>

                {/*<MenuItem icon={<FavoriteBorderOutlinedIcon />}>
                  <Link
                    to={"/my-profile"}
                    // className={props.match.path === '/my-profile' && 'active'}
                    onClick={() => props.toggleSidebarModal(true)}
                  >
                    Favorilerim
                  </Link>
                </MenuItem>*/}

                <MenuItem icon={<LibraryBooksOutlinedIcon />}>
                  <Link
                    to={"/my-adverts"}
                    // className={props.match.path === '/my-adverts' && 'active'}
                    onClick={() => props.toggleSidebarModal(true)}
                  >
                    İlanlarım
                  </Link>
                </MenuItem>
              </Menu>
            )}
            {!user.name && (
              <Menu iconShape="circle">
                <MenuItem icon={<PersonOutlineOutlinedIcon />}>
                  <Link
                    to={"/login"}
                    // className={props.match.path === '/my-profile' && 'active'}
                    onClick={() => props.toggleSidebarModal(true)}
                  >
                    Giriş Yap
                  </Link>
                </MenuItem>
                <MenuItem icon={<PersonAddIcon />}>
                  <Link
                    to={"/register"}
                    // className={props.match.path === '/my-profile' && 'active'}
                    onClick={() => props.toggleSidebarModal(true)}
                  >
                    Üye Ol
                  </Link>
                </MenuItem>
              </Menu>
            )}
          </SidebarContent>

          <SidebarFooter>
            <div
              className="sidebar-btn-wrapper d-flex justify-content-between"
              style={{
                padding: "20px 24px",
              }}
            >
              {user.name && (
                <React.Fragment>
                  <a onClick={logout} style={{ cursor: "pointer" }}>
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    <span className="menu-item-contents">Çıkış</span>
                  </a>
                  <Link
                    to={"/my-password"}
                    className="d-flex flex-row align-items-center"
                    // className={props.match.path === '/my-passwords' && 'active'}
                    onClick={() => props.toggleSidebarModal(true)}
                  >
                    <SettingsOutlinedIcon style={{ fontSize: "20px" }} />
                  </Link>
                </React.Fragment>
              )}
              {!user.name && (
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "100%" }}
                >
                  <a
                    href="https://alicilar.com"
                    target="_blank"
                    className="sidebar-btn"
                    rel="noopener noreferrer"
                  >
                    <span> Alıcılar</span>
                  </a>
                </div>
              )}
            </div>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </Modal>
  );
}

const mapDispatchToProps = {
  ...auth.actions,
  ...initial.actions,
};

export default connect(null, mapDispatchToProps)(CustomProSidebar);
