import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserProfileLayout from "markup/Layout/UserProfileLayout";
import Profilesidebar from "markup/Element/Profilesidebar";
import * as authCrud from "modules/Auth/_redux/authCrud";
import { toast } from "react-toastify";

function MyCampaign(props) {
  const [campaign, setCampaign] = useState("");

  function handleChange(e) {
    setCampaign(e.target.value);
  }

  function applyCode() {
    authCrud.applyCampaign(campaign).then((res) => {
      toast.success("Tebrikler, hediye çeki yüklendi.");
    });
  }

  return (
    <UserProfileLayout>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar {...props} />

                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx save-job browse-job table-job-bx clearfix">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Hediye Çeki
                      </h5>
                      <Link
                        to={"/"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Anasayfa
                      </Link>
                    </div>
                    <div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <p>
                            Sizinle paylaşılan hediye çekinin kodunu aşağıdaki
                            alana girerek kullanabilirsiniz.
                          </p>
                          <input
                            type="text"
                            className="form-control"
                            name="code"
                            placeholder="Hediye Çeki Kodu"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        {campaign && (
                          <button
                            className="site-button m-b30"
                            onClick={applyCode}
                          >
                            Uygula
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default MyCampaign;
