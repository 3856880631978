import React from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import * as comps from "../Layout/footerList";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Footer(props) {
  const { pages } = useSelector(
    (state) => ({
      pages: state.initial.pages,
    }),
    shallowEqual
  );

  const location = useLocation();

  return (
    <footer className="site-footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row">
                {comps.list.map((comp) => (
                  <FooterLinks
                    header={comp.header}
                    links={comp.links}
                    key={comp.id}
                    columns={
                      location.pathname?.startsWith("/ilan")
                        ? "col-md-4"
                        : "col-md-3"
                    }
                  />
                ))}
                {location.pathname?.startsWith("/ilan") ? null : (
                  <div className="col-6 col-md-3">
                    <div className="widget border-0">
                      <h5 className="m-b30" style={{ color: "#222845" }}>
                        İletişim
                      </h5>
                      <ul className="list-line">
                        <li style={{ margin: "5px 0", lineHeight: "21px" }}>
                          <div className="footer-contact align-items-center">
                            <div className="mr-2">
                              <PhoneIcon
                                style={{ fontSize: "3em", color: "#1FB284" }}
                              />
                            </div>
                            <div>
                              <h6 className="m-0 text-black">
                                7/24 Müşteri Hizmetleri
                              </h6>

                              <a
                                href={`tel:+908502555777`}
                                className="footer-contact-links"
                              >
                                0 850 2 555 777
                              </a>
                            </div>
                          </div>
                        </li>
                        <li style={{ margin: "5px 0", lineHeight: "21px" }}>
                          <div className=" footer-contact align-items-center">
                            {" "}
                            <div className="mr-2">
                              <EmailIcon
                                style={{ fontSize: "3em", color: "#1FB284" }}
                              />
                            </div>
                            <div>
                              <h6 className="m-0 text-black">Yardım Merkezi</h6>

                              <a
                                href={`mailto:iletisim@alicilar.com`}
                                className="footer-contact-links"
                              >
                                iletisim@alicilar.com
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-9">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-3 footer-logo">
                  <div className="mostion mt-2">
                    <Link to={"/"}>
                      <img
                        src={require("./../../images/logo-white.png")}
                        className="footer-bottom-logo mb-3"
                        alt="Alıcılar Footer Logo"
                      />
                    </Link>
                  </div>
                </div>

                <div className="col-12 col-md-12 col-lg-9 d-flex flex-column justify-content-end align-items-center p-2 footer-content-container">
                  <span className="footer-content">
                    <p>
                      © Copyright by Alıcılar Bilgi Teknolojileri Pazarlama ve
                      Ticaret A.Ş. All rights reserved.
                    </p>
                  </span>
                  <div
                    className="d-flex align-items-center"
                    style={{ zoom: ".8" }}
                  >
                    <div className="col">
                      <img
                        alt=""
                        src={require("./../../images/master-visa/iyzico-3.png")}
                        style={{ width: "80px" }}
                      />
                    </div>
                    <div className="col ">
                      <img
                        alt=""
                        src={require("./../../images/master-visa/master-card.png")}
                        style={{ width: "40px" }}
                      />
                    </div>
                    <div className="col ">
                      <img
                        alt=""
                        src={require("./../../images/master-visa/maestro-4.png")}
                        style={{ width: "80px" }}
                      />
                    </div>
                    <div className="col ">
                      <img
                        alt=""
                        src={require("./../../images/master-visa/visa-2.png")}
                        style={{ width: "65px" }}
                      />
                    </div>
                    <div className="col ">
                      <img
                        alt=""
                        src={require("./../../images/master-visa/visa-electron-2.png")}
                        style={{ width: "40px" }}
                      />
                    </div>
                    <div className="col ">
                      <img
                        alt=""
                        src={require("./../../images/master-visa/amex.png")}
                        style={{ width: "65px" }}
                      />
                    </div>
                    <div className="col ">
                      <img
                        alt=""
                        src={require("./../../images/master-visa/troy.png")}
                        style={{ width: "65px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-lg-3 footer-social-media d-flex align-items-center p-2">
              <ul className="list-inline m-a0">
                <li>
                  <a
                    target="_blank"
                    href={"https://www.facebook.com/alicilarcom"}
                    className="site-button white facebook circle "
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href={"https://www.instagram.com/almakdakolay/"}
                    className="site-button white instagram circle "
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

const FooterLinks = (props) => {
  const { links, columns } = props;
  return (
    <div className={`col-6 ${columns}`}>
      <div className="widget border-0">
        <h5 className="m-b30" style={{ color: "#000" }}>
          {props.header}{" "}
        </h5>
        <ul className="list-2 list-line">
          {links.map((item) => (
            <li key={item.id}>
              <Link to={`/sayfa/${item.slug}`} className="footer-contact-links">
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
