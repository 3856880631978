import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  GetAdvert: "[Advert] Advert",
  GetAdverts: "[Advert] Adverts",
  SetAdvertsOfCategory: "[Advert] Adverts of category",
  SetAdvertsOfCity: "[Advert] Adverts of city",
  SetAdverts: "[Advert] Set Adverts",
  SetRandomAdverts: "[Advert] Set Random Adverts",
  SetAdvert: "[Advert] Set Advert by ID",
  SetHiddenData: "[Advert] Set Detail hidden data",
  CleanAdvert: "[Advert] Clean Advert",
  CreateAdvert: "[Advert] Create Advert",
  BuyAdvert: "[Advert] Buy Advert",
  SetSearchResults: "[Advert] Search Advert",
  SelectCategory: "[New Advert] Select Category",

  // New Advert Actions
  NewAdvertFieldsEdit: "[New Advert] Edit New Advert",
  NewAdvertFiltersEdit: "[New Advert] Edit New Advert Filters",
  NewAdvertLocationProvince: "[New Advert] Edit Location Province",
  NewAdvertLocationDistrict: "[New Advert] Edit Location District",
  ClearNewlyCreatedAdvert: "[New Advert] Clear Newly Created Advert",
};

const initialAdvertState = {
  advertDetail: {
    title: "",
    body: "",
    user: {
      name: false,
      surname: false,
      phone: "",
      phone2: "",
      email: "",
    },
  },
  adverts: [],
  randomAdverts: [],
  advertsOfCategory: {
    results: [],
  },

  advertsOfCity: {
    results: [],
  },

  searchKeyword: {
    results: [],
  },

  // yeni ilan oluşturulduğu zaman kullanılacak dizi.
  newAdvert: {
    title: "",
    body: "",
    priceMin: "",
    priceMax: "",
    location: {
      district: null,
      province: null,
    },
    filters: [],
    selectedCategory: {
      filters: [],
    },
  },
};

export const reducer = persistReducer(
  {
    storage,
    key: "v713-alicilar-adverts",
    whitelist: ["advertDetail", "adverts", "advertsOfCategory"],
  },
  (state = initialAdvertState, action) => {
    switch (action.type) {
      case actionTypes.SetAdvert: {
        const { advert } = action.payload;

        return { ...state, advertDetail: advert };
      }

      case actionTypes.SetHiddenData: {
        const { user } = action.payload;

        return {
          ...state,
          advertDetail: {
            ...state.advertDetail,
            user,
          },
        };
      }

      case actionTypes.CreateAdvert: {
        const { advert } = action.payload;

        return {
          ...state,
          advertDetail: advert,
        };
      }

      case actionTypes.SetRandomAdverts: {
        const { adverts } = action.payload;

        return { ...state, randomAdverts: adverts };
      }

      case actionTypes.SetAdverts: {
        const { adverts } = action.payload;

        return { ...state, adverts };
      }

      case actionTypes.SetAdvertsOfCategory: {
        const { adverts } = action.payload;

        return { ...state, advertsOfCategory: adverts };
      }

      case actionTypes.SetAdvertsOfCity: {
        const { adverts } = action.payload;

        return { ...state, advertsOfCity: adverts };
      }

      case actionTypes.SetSearchResults: {
        const { adverts } = action.payload;

        return {
          ...state,
          searchKeyword: {
            results: adverts,
          },
        };
      }

      case actionTypes.CleanAdvert: {
        return { ...state, advertDetail: initialAdvertState.advertDetail };
      }

      // New Advert
      case actionTypes.SelectCategory: {
        const { category } = action.payload;

        return {
          ...state,
          newAdvert: {
            ...state.newAdvert,
            selectedCategory: category,
            category: category._id,
          },
        };
      }

      case actionTypes.NewAdvertFieldsEdit: {
        const { name, value } = action.payload;

        return {
          ...state,
          newAdvert: {
            ...state.newAdvert,
            [name]: value,
          },
        };
      }

      case actionTypes.NewAdvertFiltersEdit: {
        const { name, value } = action.payload;

        return {
          ...state,
          newAdvert: {
            ...state.newAdvert,
            filters: {
              ...state.newAdvert.filters,
              [name]: value,
            },
          },
        };
      }

      case actionTypes.NewAdvertLocationProvince: {
        const { province } = action.payload;

        return {
          ...state,
          newAdvert: {
            ...state.newAdvert,
            location: {
              ...state.newAdvert.location,
              province,
            },
          },
        };
      }

      case actionTypes.NewAdvertLocationDistrict: {
        const { district } = action.payload;

        return {
          ...state,
          newAdvert: {
            ...state.newAdvert,
            location: {
              ...state.newAdvert.location,
              district,
            },
          },
        };
      }

      case actionTypes.ClearNewlyCreatedAdvert: {
        return {
          ...state,
          newAdvert: {
            title: "",
            body: "",
            priceMin: "",
            priceMax: "",
            location: {
              district: null,
              province: null,
            },
            filters: [],
            selectedCategory: {
              filters: [],
            },
          },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setRandomAdverts: (adverts) => ({
    type: actionTypes.SetRandomAdverts,
    payload: { adverts },
  }),

  setAdverts: (adverts) => ({
    type: actionTypes.SetAdverts,
    payload: { adverts },
  }),

  // If use take advert then show hidden data, advert owner.
  setHiddenData: (user) => ({
    type: actionTypes.SetHiddenData,
    payload: { user },
  }),

  // In filter category, there are firstly loading category.
  setAdvertsOfCategory: (adverts) => ({
    type: actionTypes.SetAdvertsOfCategory,
    payload: { adverts },
  }),

  // In filter category, there are firstly loading category.
  setAdvertsOfCity: (adverts) => ({
    type: actionTypes.SetAdvertsOfCity,
    payload: { adverts },
  }),

  setAdvertsOfSearch: (adverts) => ({
    type: actionTypes.SetSearchResults,
    payload: { adverts },
  }),

  setAdvert: (advert) => ({
    type: actionTypes.SetAdvert,
    payload: { advert },
  }),

  cleanAdvert: () => ({
    type: actionTypes.CleanAdvert,
  }),

  clearNewlyCreatedAdvert: () => ({
    type: actionTypes.ClearNewlyCreatedAdvert,
  }),

  createAdvert: () => ({
    type: actionTypes.CreateAdvert,
  }),

  selectCategory: (category) => ({
    type: actionTypes.SelectCategory,
    payload: { category },
  }),

  editNewAdvert: (name, value) => ({
    type: actionTypes.NewAdvertFieldsEdit,
    payload: { name, value },
  }),

  editNewAdvertFilters: (name, value) => ({
    type: actionTypes.NewAdvertFiltersEdit,
    payload: { name, value },
  }),

  editNewAdvertProvince: (province) => ({
    type: actionTypes.NewAdvertLocationProvince,
    payload: { province },
  }),

  editNewAdvertDistrict: (district) => ({
    type: actionTypes.NewAdvertLocationDistrict,
    payload: { district },
  }),
};
