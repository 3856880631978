import React from "react";
import "css/market.css";

let AlicilarMarket = require("images/market/mockup.png");
let iosMarket = require("images/market/ios.png");
let androidMarket = require("images/market/android.png");

const Market = () => {
  return (
    <div className="section-full market-container d-flex align-items-center">
      <div className="container  h-100">
        <div className="gradient-bg apptelsection  h-100">
          <div className="container  h-100">
            <div className="fl-wrap  h-100">
              <div className="row h-100">
                <div className="col-md-4 col-xs-12 telephone-container">
                  <img
                    src={AlicilarMarket}
                    className="respimg apptel telephone"
                    alt=""
                  />
                </div>
                <div className="col-md-8 col-xs-12 d-flex align-items-center">
                  <div className="join-wrap p-3">
                    <div className="intro-item fl-wrap">
                      <h2 className="market-header">
                        Artık Cebinizde
                        <span className="market-content">
                          <br />
                          Alıcılar mobil uygulamasını siz de indirin ve tüm
                          alıcılar cebinizde olsun.
                        </span>
                      </h2>
                    </div>
                    <div className="d-flex">
                      <a
                        href="https://apps.apple.com/tr/app/al%C4%B1c%C4%B1lar/id1540652598?l=tr"
                        target="_blank"
                        className="join-wrap-btn"
                      >
                        <img
                          src={iosMarket}
                          className="apps"
                          alt="iOS Market"
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.alicilar.amobile"
                        target="_blank"
                        className="join-wrap-btn"
                      >
                        <img
                          src={androidMarket}
                          className="apps"
                          alt="Android Market"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;
